import { useEffect, useState } from "react";
import ScrollToTop from "@/components/project/ScrollToTop";
import {
  checkMediaQuery
} from "@/utils/user";
import TabButtons from "./property/TabButtons";
import {
  getFile,
  trackEvent,
  trackPageView
} from "@/utils/api";
import {
  PROPERTY_DATA_FILE_PREFIX,
  decompressEstateData,
  getPropertyFileName
} from "@/utils/map";
import Loader from "@/components/common/Loader";
import EstateOverviewTab from "./property/EstateOverviewTab";
import EstateTrendTab from "./property/EstateTrendTab";

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'trend', label: 'Trend' }
];

const EstateView = ({
  user,
  session,
  target,
  isMinimized,
  isMaximized,
  handleExpand,
  handleMaximize,
  closePropertyDetails,
  screenDim,
  userConfig,
  setUnfadedProps
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS[0].id);
  const [selectedSubtab, setSelectedSubtab] = useState(null);

  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const loadEstateData = () => {
    setErr(null);
    setLoading(true);

    getFile("e", PROPERTY_DATA_FILE_PREFIX + getPropertyFileName(target.id), txt => {
      const data = decompressEstateData(txt);
      setData(data);
      setLoading(false);
    }, err => {
      setLoading(false);
      setErr('Failed to load property');
    });
  };

  useEffect(() => {
    setUnfadedProps(new Set());
    setData(null);
    setSelectedTab('overview');
    onSwitchTab('overview');
    loadEstateData();
  }, [target]);

  const onSwitchTab = (tab, subtab) => {
    document.getElementById('project-details-modal')?.scrollTo({ top: 0 });
  
    // reset subtab if not specified
    setSelectedSubtab(subtab);

    if (subtab) {
      trackEvent(`property_tab_${tab}_${subtab}`);
    } else {
      trackEvent(`property_tab_${tab}`);
    }
  };

  const mediaMatches = checkMediaQuery();

  const hasData = data && data?.brief?.name === target?.id;

  useEffect(() => {
    loadEstateData();
    trackPageView('property_detail', {
      type: 'estate',
      property: target.id
    });
  }, []);

  return (
    <>
      {!isMinimized && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
            </div>
          }

          <div className="px-10">
            <TabButtons
              tabs={TABS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSwitchTab={onSwitchTab}
              session={session}
              markerName={target.id}
              projectName={target.project}
              closePropertyDetails={closePropertyDetails}
            />
          </div>

          {err
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >
                Estate not found
              </div>
            </div>
          }

          {!err
            && <div
              className={`modal-body ${
                mediaMatches && !isMaximized
                  ? 'modal-body-prop-m'
                  : 'modal-body-prop'
              } watermark d-flex`}
            >
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >

                <ScrollToTop />

                {(loading || !hasData)
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }

                {/* detail page tabs */}
                {hasData && selectedTab === 'overview'
                  && <EstateOverviewTab
                    user={user} data={data.brief} isMaximized={isMaximized} screenDim={screenDim}
                    target={target} userConfig={userConfig}
                  />
                }

                {hasData && selectedTab === 'trend'
                  && <EstateTrendTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
                    session={session} target={target} selectedSubtab={selectedSubtab}
                  />
                }

              </div>
            </div>
          }

        </div>
      )}
    </>
  );
};

export default EstateView;
