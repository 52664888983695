import { queryRef, executeQuery, mutationRef, executeMutation, validateArgs } from 'firebase/data-connect';

export const connectorConfig = {
  connector: 'default',
  service: 'Realsmart',
  location: 'asia-southeast1'
};

export function createPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'CreatePropertyLikes', inputVars);
}
export function createPropertyLikes(dcOrVars, vars) {
  return executeMutation(createPropertyLikesRef(dcOrVars, vars));
}
export function deletePropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'DeletePropertyLikes', inputVars);
}
export function deletePropertyLikes(dcOrVars, vars) {
  return executeMutation(deletePropertyLikesRef(dcOrVars, vars));
}
export function listPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'ListPropertyLikes', inputVars);
}
export function listPropertyLikes(dcOrVars, vars) {
  return executeQuery(listPropertyLikesRef(dcOrVars, vars));
}
export function getPropertyLikeByIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetPropertyLikeById', inputVars);
}
export function getPropertyLikeById(dcOrVars, vars) {
  return executeQuery(getPropertyLikeByIdRef(dcOrVars, vars));
}
export function getPostRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetPost', inputVars);
}
export function getPost(dcOrVars, vars) {
  return executeQuery(getPostRef(dcOrVars, vars));
}
export function getCommentsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetComments', inputVars);
}
export function getComments(dcOrVars, vars) {
  return executeQuery(getCommentsRef(dcOrVars, vars));
}
export function getPublicFeedsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetPublicFeeds', inputVars);
}
export function getPublicFeeds(dcOrVars, vars) {
  return executeQuery(getPublicFeedsRef(dcOrVars, vars));
}
export function getNewsfeedRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetNewsfeed', inputVars);
}
export function getNewsfeed(dcOrVars, vars) {
  return executeQuery(getNewsfeedRef(dcOrVars, vars));
}
