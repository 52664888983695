import PropTypes from "prop-types";
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import { Sidebar } from "react-pro-sidebar";
import TabButtons from "../mapv2/property/TabButtons";
import { useState } from "react";
import UserProfile from "../account/UserProfile";
import LikedProperties from "../account/LikedProperties";
import { PROFILE_OPTIONS, isLoggedIn } from "@/utils/user";
import UserHistory from "../account/UserHistory";
import LikedPosts from "../account/LikedPosts";
import { trackEvent } from "@/utils/api";

// import PlanList from "./profile/PlanList";
// import { getUserDisplayName, isAdminTier } from "@/utils/user";


const MobileMenu = ({
  user,
  session,
  onUnlikeProperty,
  onViewProperty,
  isMap = false
}) => {
  const navigate = useNavigate();

  const [display, setDisplay] = useState(PROFILE_OPTIONS[0].id);

  const onLogout = () => {
    firebase.auth().signOut().then(() => {
      // clear user tracking on Umami when logged out
      if (window.umami && typeof window.umami.reset === 'function') {
        window.umami.reset();
      }

      // navigate to login page
      navigate('/login');
    });
  };

  // const onAdmin = () => {
  //   navigate('/admin');
  // };

  const closeModal = () => {
    const closeButton = document.querySelector('#m-acct-close');
    if (closeButton) {
      closeButton.click();
    }
    setDisplay(PROFILE_OPTIONS[0].id);
  };

  return (
    <>
      <div className="pro-header d-flex align-items-center justify-between py-5 border-bottom-light">
        <TabButtons
          tabs={PROFILE_OPTIONS}
          selectedTab={display}
          setSelectedTab={selected => {
            setDisplay(selected);

            trackEvent(`profile_tab_${selected}`);
          }}
          session={session}
        />

        <div
          id="m-acct-close"
          className="fix-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setDisplay(PROFILE_OPTIONS[0].id);
          }}
        >
          <i className="icon icon-close"></i>
        </div>
      </div>
      
      <Sidebar width="400" backgroundColor="#fff" className="mt-0 mb-0 py-10">
        {/* <div className="text-18 fw-500 lh-15 ml-20 mt-20">My Subscription</div>
        <PlanList user={user} /> */}

        {isLoggedIn(user)
          && <div className="acct-h">
            {display === 'bio'
              && <UserProfile
                user={user}
              />
            }
            {display === 'likes'
              && <LikedProperties
                user={user}
                onViewProperty={prop => {
                  closeModal();
                  onViewProperty(prop);
                }}
                onUnlikeProperty={onUnlikeProperty}
                isMap={isMap}
              />
            }
            {display === 'posts'
              && <LikedPosts
                user={user}
              />
            }
            {display === 'history'
              && <UserHistory
                user={user}
              />
            }
          </div>
        }

      </Sidebar>

      <div className="pro-footer py-10 border-top-light">
        {/* {isAdminTier(user?.claims)
          && <button
            className="button fw-button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white mb-10"
            onClick={onAdmin}
          >
            Admin
          </button>
        } */}
        <button
          className="button fw-button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white"
          onClick={onLogout}
        >
          Logout
        </button>
      </div>
    </>
  );
};

MobileMenu.propTypes = {
  user: PropTypes.object,
};

export default MobileMenu;
