import PropNotificationRow from "./PropNotificationRow";

const PropNotifications = ({
  data
}) => {
  return (
    <>
      <div className="like-props">
        {data.map(r =>
          <PropNotificationRow data={r} />
        )}
      </div>
    </>
  );
};

export default PropNotifications;
