import { NA } from "@/utils/convert";
import {
  MAP_MODE_HDB,
  getHdbBlockLabel,
  getPropertyIconByType
} from "@/utils/map";
import { Tooltip } from "react-tooltip";

const PropertyRow = ({
  id,
  mode,
  data,
  onView,
  usePlaceImg = false,
  dismissable = false
}) => {
  const generateFieldShortVal = (arr) => {
    if (!arr || arr.length === 0) return NA;
    return (
      <span
        data-tooltip-id={`bot-tooltip-${id}`}
        data-tooltip-html={arr.join('<br />')}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        {arr[0]} {arr.length > 1 ? `+${arr.length - 1}`: ''}
      </span>
    );
  };

  const getTxCountSimplified = (tx) => tx >= 0 ? (
    tx >= 1000
      ? `${Math.round(tx / 1000).toFixed(0)}K`
      : tx
  ) : NA

  const isHdb = mode === MAP_MODE_HDB;

  const iconType = isHdb ? 'hdb'
    : (data.landed ? 'house' : getPropertyIconByType(data.types));
  const propType = iconType === 'hdb'
    ? 'HDB'
    : (
      iconType === 'condo' || iconType === 'flat'
        ? 'Condo'
        : (
            iconType === 'house'
              ? 'Landed'
              : 'Mixed'
          )
      )

  const getPropertyImgName = (sharePlaceId) => sharePlaceId
    .toUpperCase()
    .split('')
    .filter(ch => /[A-Z0-9]/.test(ch))
    .join('')
    .replace(/ /g, '_');

  const imgUrl = data.sharePlaceId
    ? (
        usePlaceImg
          ? `https://realsmart.global.ssl.fastly.net/i/${data.sharePlaceId}/0.jpg`
          : `https://realsmart.global.ssl.fastly.net/ir/${getPropertyImgName(data.sharePlaceId)}.jpg`
      )
    : `/img/general/thumbs/${iconType}.png`;

  return (
    <>
      <div className="border-top-light px-0 py-10">
        <div className="px-0">
          <div className="d-flex px-0 align-items-center">
            <img
              className="p-2 prop-img-icon noselect"
              src={imgUrl}
              style={{
                borderRadius: (data.sharePlaceId ? '20%' : '0')
              }}
            />
            <div className="p-2 flex-grow-1">
              <div className="fw-600 text-dark text-18">
                {
                  isHdb
                    ? getHdbBlockLabel(data)
                    : (
                        iconType === 'house'
                          ? data.marker
                          : data.project
                      )
                }
              </div>
              <div className="text-12 text-light-1">
                <i className="icon-location-2 mr-5" />
                {
                  isHdb
                    ? `${data.street} [${data.postal}]`
                    : (
                        data.streets
                          ? generateFieldShortVal(data.streets, true)
                          : (iconType === 'house' ? data.project : data.marker)
                      )
                }
              </div>
            </div>
            <button
              className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 noselect"
              onClick={() => onView?.(data)}
              {...(
                dismissable
                  ? {
                    'data-bs-dismiss': "offcanvas"
                    }
                  : {}
              )}
            >
              <i className="icon-location-2" />
              <span className="px-5">
                View
              </span>
            </button>
          </div>
          <div className="d-flex ml-10">
            <div
              className="p-2 prop-info-box prop-rs-box text-center"
              data-tooltip-id={`bot-tooltip-${id}`}
              data-tooltip-html={`
              Profitability rank across Singapore
              <br />
              Maximum score of 5.0 for top properties
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            >
              <div className="prop-rs-label">REALSCORE</div>
              <div className="text-18 fw-600">{data.score !== null && data.score !== undefined ? data.score.toFixed(1) : NA}</div>
            </div>
            <div className="p-2 flex-grow-1 text-center d-flex">
              <div
                className="p-2 prop-info-w"
                data-tooltip-id={`bot-tooltip-${id}`}
                data-tooltip-html={`
                Percentage of transactions that are profitable
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                <div className="prop-rs-label">{isHdb ? 'PRICE INCREASE' : 'PROFITABLE'} %</div>
                <div className="text-18 fw-600">{data.profitable !== null ? `${data.profitable.toFixed(0)}%` : NA}</div>
              </div>
              <div
                className="p-2 prop-info-w border-left-light"
                data-tooltip-id={`bot-tooltip-${id}`}
                data-tooltip-html={`
                Total number of transactions
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                <div className="prop-rs-label">TRANSACTIONS</div>
                <div className="text-18 fw-600">{getTxCountSimplified(data.totalTx)}</div>
              </div>
              <div
                className="p-2 prop-info-w border-left-light"
                data-tooltip-id={`bot-tooltip-${id}`}
                data-tooltip-html={`
                Type of property: ${isHdb ? 'HDB' : data.types.join(', ')}
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                <div className="prop-rs-label">PROPERTY TYPE</div>
                <div className="text-18 fw-600">{propType}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tooltip id={`bot-tooltip-${id}`} />
    </>
  );
};

export default PropertyRow;
