import { noSelectClass } from "@/utils/user";
import PerformancePanel from "./Overview/PerformancePanel";
import Factsheet from "./Overview/Factsheet";


const EstateOverviewTab = ({
  user,
  data,
  target,
  isMaximized,
  screenDim,
}) => {
  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600) ? 6 : 12;

  const getStreetsLabel = (streets) => streets?.length > 3 ? (
    <div
      className="d-flex items-center text-12 text-light-1 cursor-pointer"
      data-tooltip-id="map-tooltip"
      data-tooltip-html={`<span class="text-10">${data.streets.map((s, i) => i % 5 === 4 ? `${s},<br />` : (i !== data.streets.length - 1 ? `${s}, `: s)).join('')}</span>`}
      data-tooltip-variant="dark"
      data-tooltip-place="bottom"
    >
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets.slice(0, 3).join(', ')} +{data.streets.length - 3} more
    </div>
  ) : (
    <div className="d-flex items-center text-12 text-light-1">
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets?.join(', ')}
    </div>
  );

  const getTenuresLabel = (tenures) => tenures?.length > 3 ? (
    <div className="col-auto d-flex align-content-start flex-wrap py-0 px-0">
      {data.tenures?.slice(0, 3).map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5">
          {tenure}
        </div>
      ))}
      <div
        className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5 cursor-pointer"
        data-tooltip-id="map-tooltip"
        data-tooltip-html={`<span class="text-10">${data.tenures.slice(3).join('<br />')}</span>`}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        +{data.tenures.length - 3} more
      </div>
    </div>
  ) : (
    <div className="col-auto d-flex align-content-start flex-wrap py-0">
      {data.tenures?.map(tenure => (
        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
          {tenure}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <section className="pt-0" id="reviews">
        <div className={`px-5 ${noSelectClass(user)}`}>
          <div className="row y-gap-10 justify-between">
            <div className="col-12 row x-gap-50 y-gap-10 py-0">
              <div className="row justify-between items-end">
                <div className="col-auto">
                  <div className="row x-gap-20  items-center">
                    <div className="col-auto">
                      <h1 className="text-16 sm:text-20 fw-600 mt-10 mb-5">
                        {data.name}
                      </h1>
                    </div>
                  </div>

                  <div className="row x-gap-20 y-gap-0 items-center">
                    <div className="col-auto">
                      {getStreetsLabel(data.streets)}
                    </div>
                    {getTenuresLabel(data.tenures)}
                  </div>

                </div>
              </div>
              
            </div>

            <div className={`col-${chartColGrid} text-blue-1`}>
              <PerformancePanel
                data={data}
                target={target}
              />
            </div>

            <Factsheet
              data={data}
              isMaximized={isMaximized}
              screenDim={screenDim}
              target={target}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EstateOverviewTab;
