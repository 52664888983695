import { checkMediaQuery } from "@/utils/user";
import { useEffect, useState } from "react";
import ScrollToTop from "@/components/project/ScrollToTop";
import CompareView from "./property/CompareView";
import Loader from "../common/Loader";
import { trackPageView } from "@/utils/api";

const CompareProView = ({
  user,
  target,
  screenDim,
  isMaximized,
  isMinimized,
  handleMaximize,
  handleExpand,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  closeDetails,
  getProjectLabel,
  onCompareSearch,
  unfadedProps,
  setUnfadedProps,
  setFocus
}) => {
  const [loading, setLoading] = useState(false);

  const compData = { t: {}, p: {}, d: {}, s: {} };
  Object.keys(compareList).forEach(p => {
    compData.t[p] = compareList[p].t;
    compData.p[p] = compareList[p].p;
    compData.d[p] = compareList[p].d;
    compData.s[p] = compareList[p].s;
  });

  const setCompData = (data) => {
    const newCompareList = {};
    Object.keys(data.t).forEach(p => {
      newCompareList[p] = {
        t: data.t[p],
        p: data.p[p] ?? [],
        d: data.d[p] ?? [],
        s: data.s[p] ?? [],
      };
    });
    setCompareList(newCompareList);
  };

  const mediaMatches = checkMediaQuery();

  useEffect(() => {
    trackPageView('compare_pro');
  }, []);

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect">
            <i className="p-2 icon-destination text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Realsmart Compare
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closeDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="compare-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >

              <ScrollToTop />
            
              <CompareView
                user={user}
                target={target}
                loading={loading}
                setLoading={setLoading}
                compData={compData}
                setCompData={setCompData}
                screenDim={screenDim}
                isMaximized={isMaximized}
                goToProperty={goToProperty}
                setFocusOn={setFocusOn}
                compareList={compareList}
                setCompareList={setCompareList}
                getProjectLabel={getProjectLabel}
                onCompareSearch={onCompareSearch}
                unfadedProps={unfadedProps}
                setUnfadedProps={setUnfadedProps}
                setFocus={setFocus}
                isComparePro
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompareProView;
