import { logPropertyTabView } from "@/utils/api";

const TabButtons = ({
  session,
  isMaximized,
  markerName,
  projectName,
  tabs,
  selectedTab,
  setSelectedTab,
  onSwitchTab,
  onPreSwitchTab,
  closePropertyDetails
}) => {
  const isFullLgScreen = isMaximized && window.innerWidth > 770;

  return (
    <div className="d-flex flex-wrap justify-content-start px-0 py-0 mt-10 mb-5 ml-0 mr-0 noselect">
      <div className={`p-2 flex-grow-1 d-flex flex-wrap ${isFullLgScreen ? 'justify-content-center' : 'justify-content-start'} py-0`}>
        {tabs.map(tab => (
          <button
            className={`p-2 button mr-5 ${
              selectedTab === tab.id
                ? '-dark-1 bg-blue-1 text-white'
                : '-blue-1 bg-blue-1-05 text-blue-1'
            } h-30 px-5 rounded-100 text-12`}
            key={tab.id}
            onClick={() => {
              onPreSwitchTab?.(tab.id);
              setSelectedTab(tab.id);
              onSwitchTab?.(tab.id);
              logPropertyTabView(tab.id.toUpperCase(), session, markerName, projectName);
            }}
          >
            {tab.icon && <i className={`icon-${tab.icon} mr-5`} />}
            {tab.label}
          </button>
        ))}
      </div>
      
      {closePropertyDetails
        && <button
          className="p-2 button h-30 px-10 text-16"
          onClick={() => closePropertyDetails()}
        >
          <i className="icon-close py-5" />
        </button>
      }
    </div>
  );
};

export default TabButtons;
