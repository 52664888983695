import {
  checkMediaQuery,
  noSelectClass
} from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AmenityTable from "./property/Table/AmenityTable";
import { useEffect, useState } from "react";
import {
  calculateDist,
  formatDistanceLabel
} from "@/utils/convert";
import {
  getNearbyCondos,
  getNearbyHdbs,
  getNearbyLanded,
  trackEvent,
  trackPageView
} from "@/utils/api";
import {
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  getPropTypeLabel
} from "@/utils/map";
import Loader from "../common/Loader";
import {
  LOCATION_SCHOOL,
  getBlockUnitFromAddress
} from "@/utils/areas";
import MaxDistInput from "./filter/MaxDistInput";

const PROP_OPTIONS = [
  {
    id: MAP_MODE_CONDO,
    label: 'Condo / Apt / EC'
  },
  {
    id: MAP_MODE_LANDED,
    label: 'Landed'
  },
  {
    id: MAP_MODE_HDB,
    label: 'HDB'
  }
];

const DEFAULT_MAX_DIST = 1000;

const AmenityView = ({
  user,
  userConfig,
  target,
  setTarget,
  mapData,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToBlock,
  goToHdbBlock,
  mode,
  setMode,
  filterSelected,
  setUnfadedProps
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // filters
  const [filterProp, setFilterProp] = useState(mode);
  const [filterMaxDist, setFilterMaxDist] = useState(DEFAULT_MAX_DIST);

  const loadData = (filterProp) => {
    setLoading(true);
    setData([]);
    const onData = (data) => {
      const formattedData = data
        .map(r => {
          const lat = r.coords[0];
          const lng = r.coords[1];
          return {
            ...r,
            lat,
            lng,
            distance: calculateDist(target.lat, target.lng, lat, lng)
          };
        })
        .filter(r => r.distance <= DEFAULT_MAX_DIST)
        .sort((a, b) => a.distance - b.distance);
      setData(formattedData);
      setLoading(false);
    };
    const onErr = (err) => {
      setLoading(false);
    };
    if (filterProp === MAP_MODE_CONDO) {
      getNearbyCondos(target.lat, target.lng, '1 km', 200, onData, onErr);
    } else if (filterProp === MAP_MODE_LANDED) {
      getNearbyLanded(target.lat, target.lng, '1 km', 200, onData, onErr);
    } else if (filterProp === MAP_MODE_HDB) {
      getNearbyHdbs(target.lat, target.lng, '1 km', 200, onData, onErr);
    }
  };

  useEffect(() => {
    setUnfadedProps(new Set());
    loadData(mode);
  }, [target]);

  useEffect(() => {
    trackPageView(`amenity_detail_${target.type === LOCATION_SCHOOL ? 'school' : 'station'}`, {
      type: target.type,
      [target.type === LOCATION_SCHOOL ? 'school' : 'station']: target.id
    });
  }, []);

  const filterProps = (r) => {
    // filter by max distance
    if (r.distance > filterMaxDist) return false;
    return true;
  };

  const mediaMatches = checkMediaQuery();

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    if (filterProp === MAP_MODE_HDB) {
      goToHdbBlock(data.data[idx].store);
    } else {
      goToProperty(data.data[idx].marker, data.data[idx].store);
    }
  };

  const goToBlockFromRow = (data) => {
    const idx = data.cell.row.index;
    let block = getBlockUnitFromAddress(data.data[idx].address);
    const match = block.block.match(/^\S+/);
    block = match ? match[0] : block.block;
    goToBlock({
      ...data.data[idx],
      block,
    });
  };

  const getDisplayLabel = (target) => {
    if (target.name) {
      return target.name;
    }
    if (target.names) {
      const names = target.names.filter(n => !n.includes('MOE KINDERGARTEN'));
      if (names.length > 0) {
        return names[0];
      }
    }
    return '';
  };

  const getLabelHint = (label) => {
    if (!label) return [];
    return label.split(',');
  };

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect">
            <i className="p-2 icon-location-2 text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">
                  ${getLabelHint(target.name ?? target.names?.join(',')).join('<br />')}
                </div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {getDisplayLabel(target)}
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closePropertyDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          {(!data || data.length === 0)
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center text-14 py-40 ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                }`}
              >
                No data yet
              </div>
            </div>
          }

          {data && data.length > 0
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                }`}
              >

                <ScrollToTop />
              
                <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                  <div className={`data-table ${noSelectClass(user)}`}>

                    <div className="d-flex noselect mt-10">
                      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          data-bs-offset="0,10"
                          data-tooltip-id="map-tooltip"
                          data-tooltip-html={`
                            <div class="map-tooltip-content">
                            <span class="fw-500">Filter by Property Type</span>
                            <br />
                            <span class="map-tooltip-hint">This filter overrides the map's filter</span>
                            </div>
                          `}
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                        >
                          <span className="js-dropdown-title text-12">{
                            PROP_OPTIONS.find(o => o.id === filterProp).label
                          }</span>
                          <i className="icon icon-chevron-sm-down text-7 ml-5" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                          <div className="text-12 y-gap-5 js-dropdown-list">
                          {PROP_OPTIONS.map((option, index) => (
                            <div key={index}>
                              <button
                                className={`d-block js-dropdown-link ${
                                  filterProp === option.id ? "text-blue-1 " : ""
                                }`}
                                onClick={() => {
                                  setFilterProp(option.id);
                                  if (mode !== option.id) setMode(option.id);
                                  loadData(option.id);
                                  trackEvent(`amenity_tab_switch_proptype_${getPropTypeLabel(option.id)}`);
                                }}
                              >
                                <span>{option.label}</span>
                              </button>
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>

                      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad ml-5">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          data-bs-offset="0,10"
                          data-tooltip-id="map-tooltip"
                          data-tooltip-html={`
                            <div class="map-tooltip-content">
                            <span class="fw-500">Filter by Distance</span>
                            <br />
                            <span class="map-tooltip-hint">Set the maximum distance of property to filter</span>
                            </div>
                          `}
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                        >
                          <span className="js-dropdown-title text-12">Max distance: {formatDistanceLabel(filterMaxDist)}</span>
                          <i className="icon icon-chevron-sm-down text-7 ml-5" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                          <div className="text-12 y-gap-5 js-dropdown-list">
                            <MaxDistInput
                              defaultDist={filterMaxDist}
                              setMaxDist={setFilterMaxDist}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-2 flex-grow-1"></div>

                    </div>
                    
                    <AmenityTable
                      data={data.filter(filterProps)}
                      userConfig={userConfig}
                      goToProperty={goToPropertyFromRow}
                      goToBlock={goToBlockFromRow}
                      mode={filterProp}
                    />
                  </div>
                </section>
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default AmenityView;
