import { NA } from "@/utils/convert";
import {
  MAP_MODE_HDB,
  getMarkerScaleByTx,
  getPropertyMarkerColor,
  getPropertyTextColor
} from "@/utils/map";
import { Marker } from "react-map-gl/maplibre";

const AreaMarker = ({
  idx,
  area,
  onClick,
  onMouseEnter,
  onMouseLeave,
  zoom,
  matchMedia,
  scheme,
  unfadedProps,
  mode,
  isCluster = false
}) => {
  const markerScale = isCluster
    ? getMarkerScaleByTx(area.totalTx, 0.5, 1.5, 20, zoom > 14 ? 3000 : 6000)
    : getMarkerScaleByTx(area.totalTx, 0.5, 1.3, 100, 80000);
  const markerColor = getPropertyMarkerColor(scheme, area, 'profitable');

  const markerOpacity = unfadedProps.size === 0
    || area.projects.some(p => unfadedProps.has(mode === MAP_MODE_HDB ? p.postal : `${p.marker}_${p.projectId}`))
      ? 1 : 0.3;

  return (
    <Marker
      key={`arm-${idx}`}
      latitude={area.lat}
      longitude={area.lng}
      anchor="bottom"
      onClick={onClick}
    >
      <div
        style={{
          opacity: markerOpacity,
          transform: `scale(${markerScale})`
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...(matchMedia ? {} : {
          'data-tooltip-id': isCluster ? 'cluster-tooltip' : 'area-tooltip',
          'data-tooltip-html': matchMedia
            ? `<div class="map-tooltip-content"><span class="fw-500">${
              isCluster
                ? `${area.totalProjects} Properties`
                : `${
                    area.mapMode === MAP_MODE_HDB
                      ? ''
                      : 'District '
                  }${area.id}`
              }</span>${
                isCluster
                  ? ''
                  : `<div class="text-10 fw-500 mb-5">${area.name}</div>`
              }</div>`
            : (
                isCluster
                ? `
                  <div class="map-tooltip-content">
                  <span class="fw-500">${area.totalProjects} Properties</span>
                  <div class="map-tooltip-l-content">
                  Avg Profitable %: <span class="${getPropertyTextColor(scheme, area, 'profitable')}">${area.profitable !== null ? area.profitable.toFixed(0) + '%' : NA}</span>
                  <br />
                  Total Transactions: ${(area.totalTx ?? 0).toLocaleString()}
                  </div>
                  <span class="map-tooltip-hint">Click to view details</span>
                  </div>
                  `
                : `
                  <div class="map-tooltip-content">
                  <div class="fw-500">${area.mapMode === MAP_MODE_HDB ? '' : 'District '}${area.id}</div>
                  <div class="text-10 fw-500 mb-5">${area.name}</div>
                  <div class="map-tooltip-l-content">
                  Properties: ${area.totalProjects}
                  <br />
                  Avg Profitable %: <span class="${getPropertyTextColor(scheme, area, 'profitable')}">${area.profitable !== null ? area.profitable.toFixed(0) + '%' : NA}</span>
                  <br />
                  Total Transactions: ${(area.totalTx ?? 0).toLocaleString()}
                  </div>
                  <span class="map-tooltip-hint">Click to view details</span>
                  </div>
                  `
              ),
          'data-tooltip-variant': "dark",
          'data-tooltip-place': "bottom"
        })}
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: markerColor,
            stroke: 'none',
          }}
        >
          <circle cx="12" cy="10" r="8" fill={markerColor} />
          <text x='12' y='12.5' textAnchor="middle" fill="#fff" style={{ fontSize: '7px', fontWeight: '600' }} >
            {area.totalProjects}
          </text>
        </svg>
      </div>
    </Marker>
  );
};

export default AreaMarker;
