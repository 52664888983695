import { useCallback, useEffect, useRef, useState } from "react";
import Map, {
  Layer,
  Marker,
  Popup,
  Source,
  NavigationControl
} from "react-map-gl/maplibre";
import { LngLatBounds, addProtocol } from "maplibre-gl";
import { PMTiles, Protocol } from "pmtiles";
import Supercluster from "supercluster";
import { debounce } from 'lodash';
import { Tooltip } from "react-tooltip";
import {
  DEFAULT_MAP_THEME,
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_ESTATE,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING,
  MARKET_SEGMENT_ID_MAP,
  getMapStyleFromTheme,
  getTenureValue,
  isRegion,
  transformMapboxStyleRequest
} from "@/utils/areas";
import RadiusOverlay from "./map/RadiusOverlay";
import {
  DEBOUNCE_TIMING,
  trackEvent
} from "@/utils/api";
import {
  checkMediaQuery,
  checkMobile,
  noSelectClass
} from "@/utils/user";
import AreaMarker from "./map/AreaMarker";
import MapButtons from "./map/MapButtons";
import PropertyMarker from "./map/PropertyMarker";
import {
  CONDO_MAP_FILTER_OPTIONS,
  HDB_MAP_FILTER_OPTIONS,
  LANDED_MAP_FILTER_OPTIONS,
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  OVERLAY_TYPES_HINT,
  OVERLAY_TYPES_MAP,
  generateLinesData,
  getOverlayFill,
  getOverlaysOpacity,
  getProjectKeyByTarget,
  groupMarkerByArea,
  groupPropsByMarker
} from "@/utils/map";
import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_REGIONS,
  FILTER_AVG_PRICE,
  FILTER_AVG_UNIT_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_HDB_FLAT_TYPE,
  FILTER_HDB_LOCATION,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS,
  hasAnyValue,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import Sidebar from "@/components/common/Sidebar";
import { convertMapUrl } from "@/utils/url";
import {
  calculateAgeYear,
  calculateHdbAge,
  getPropTypeFilterSelected
} from "@/utils/convert";
import BlockMarker from "./map/BlockMarker";
import UpcomingMarker from "./map/UpcomingMarker";
import MapSideButtons from "./map/MapSideButtons";
import UserMarker from "./map/UserMarker";
import HdbMarker from "./map/HdbMarker";

const DEFAULT_PROPERTY_ZOOM = 17;
const MAX_CLUSTER_ZOOM = 18;
const DEFAULT_AREA_ZOOM = 12;
const DEFAULT_FULL_ZOOM = 10;
const MIN_CLUSTER_DENSE_ZOOM = 15;

// const MARKER_SCHEME_OPTIONS = [
//   {
//     id: 'profitable',
//     label: 'Profitable %'
//   }
// ];

const protocol = new Protocol();
addProtocol("pmtiles", protocol.tile);

const SUPERCLUSTER_RADIUS = 38;
const LANDED_SUPERCLUSTER_RADIUS = 18;

const MainMap = ({
  user,
  target,
  focus,
  setFocus,
  mapData,
  hdbMapData,
  landedMapData,
  goToMarker,
  goToArea,
  goToStation,
  goToSchool,
  goToUpcoming,
  goToHdbBlock,
  isMinimized,
  isMaximized,
  loadAdvMapData,
  hasAdvMapData,
  hasAdvLandedMapData,
  hasAdvHdbMapData,
  filterSelected,
  setFilterSelected,
  viewComparePro,
  hasSearchResults,
  userConfig,
  setProfitableColorScheme,
  gpsLocation,
  fetchGpsLocation,
  mode,
  setMode,
  allowModeSwitch,
  incomingFilter,
  actionSignal,
  setActionSignal,
  unfadedProps
}) => {
  const mapRef = useRef();

  const [allowInteraction, setAllowInteraction] = useState(true);
  const [hasInitGpsLocation, setHasInitGpsLocation] = useState(false);
  // const [showZoomHint, setShowZoomHint] = useState(true);

  const [mapStyle, setMapStyle] = useState(DEFAULT_MAP_THEME);
  const [zoom, setZoom] = useState(DEFAULT_FULL_ZOOM);
  const [pitch, setPitch] = useState(0);
  const [bearing, setBearing] = useState(0);
  const [blgOpacity, setBlgOpacity] = useState(0.6);

  const [sideContent, setSideContent] = useState(null);

  // const [markerScheme, setMarkerScheme] = useState(MARKER_SCHEME_OPTIONS[0].id);

  const [isHoverMarker, setIsHoverMarker] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [hiddenOverlayIds, setHiddenOverlayIds] = useState([]);
  const [overlayOpacity, setOverlayOpacity] = useState(0.2);

  const filterProperties = (props, filterSelected) => props.filter(p => {
    const areaFilter = new Set(Object.keys(filterSelected[FIELD_AREAS])
      .filter(area => filterSelected[FIELD_AREAS][area] && !isRegion(area)));

    const districtFilter = new Set(Object.keys(filterSelected[FIELD_DISTRICTS])
        .filter(district => filterSelected[FIELD_DISTRICTS][district]));

    const regionFilter = new Set(Object.keys(filterSelected[FIELD_REGIONS])
        .filter(region => filterSelected[FIELD_REGIONS][region])
        .map(region => MARKET_SEGMENT_ID_MAP[region]))

    if (hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      const age = mode === MAP_MODE_HDB ? calculateHdbAge(p.completion) : calculateAgeYear(p.tenureDate, p.completion);
      if (hasMin) {
        const minAge = filterSelected[FILTER_PROPERTY_AGE.field].value.min;
        if (age === null || age < minAge) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROPERTY_AGE, filterSelected[FILTER_PROPERTY_AGE.field]);
      if (hasMax) {
        const maxAge = filterSelected[FILTER_PROPERTY_AGE.field].value.max;
        if (age > maxAge) return false;
      }
    }

    if (mode === MAP_MODE_CONDO) {
      // transaction level filter
      if (hasFilterSelected(FILTER_AVG_PRICE, filterSelected) || hasFilterSelected(FILTER_SIZE, filterSelected)) {
        const minPrice = hasValidInput(filterSelected[FIELD_MIN_PRICE]) ? parseFloat(filterSelected[FIELD_MIN_PRICE]) : null;
        const maxPrice = hasValidInput(filterSelected[FIELD_MAX_PRICE]) ? parseFloat(filterSelected[FIELD_MAX_PRICE]) : null;
        const minSize = hasValidInput(filterSelected[FIELD_MIN_SIZE]) ? parseFloat(filterSelected[FIELD_MIN_SIZE]) : null;
        const maxSize = hasValidInput(filterSelected[FIELD_MAX_SIZE]) ? parseFloat(filterSelected[FIELD_MAX_SIZE]) : null;
        if (p.areaPriceRange.length === 0) return false;  // since no area data, if price/size selected will just skip
        const propertyRanges = p.areaPriceRange
          .filter(r => (minPrice === null || r.min >= minPrice) && (maxPrice === null || r.max <= maxPrice))
          .filter(r => (minSize === null || r.area >= minSize) && (maxSize === null || r.area <= maxSize))
        if (propertyRanges.length === 0) return false;  // no transactions match the price/size selected
      }
    } else {
      // for landed just continue as it
      if (hasFilterSelected(FILTER_AVG_PRICE, filterSelected)) {
        const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
        if (hasMin) {
          const minPrice = filterSelected[FIELD_MIN_PRICE];
          if (p.minPrice === null || p.minPrice < minPrice) return false;
        }
        const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
        if (hasMax) {
          const maxPrice = filterSelected[FIELD_MAX_PRICE];
          if (p.maxPrice === null || p.maxPrice > maxPrice) return false;
        }
      }

      if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
        const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
        if (hasMin) {
          const minSize = parseFloat(filterSelected[FIELD_MIN_SIZE]);
          if (p.minSize < minSize) return false;
        }
        const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
        if (hasMax) {
          const maxSize = parseFloat(filterSelected[FIELD_MAX_SIZE]);
          if (p.maxSize > maxSize) return false;
        }
      }
    }

    if (hasFilterSelected(FILTER_PROFITABLE, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMin) {
        const minProfit = filterSelected[FILTER_PROFITABLE.field].value.min;
        if (p.profitable < 0 || p.profitable === null || p.profitable < minProfit) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PROFITABLE, filterSelected[FILTER_PROFITABLE.field]);
      if (hasMax) {
        const maxProfit = filterSelected[FILTER_PROFITABLE.field].value.max;
        if (p.profitable < 0 || p.profitable === null || p.profitable > maxProfit) return false;
      }
    }

    if (hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)) {
      const completedYear = !!p.completion ? parseInt(p.completion) : null;
      const minDate = filterSelected[FILTER_COMPLETION_DATE.field[0]];
      if (minDate !== null) {
        const startYear = minDate.getFullYear();
        if (!!startYear && completedYear < startYear) return false;
      }
      const maxDate = filterSelected[FILTER_COMPLETION_DATE.field[1]];
      if (maxDate !== null) {
        const endYear = maxDate.getFullYear();
        if (!!endYear && (!completedYear || completedYear > endYear)) return false;
      }
    }

    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      const propFilter = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
      const types = new Set(p.types);
      if (!propFilter.some(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_HDB_FLAT_TYPE, filterSelected)) {
      const propFilter = getPropTypeFilterSelected(filterSelected, FILTER_HDB_FLAT_TYPE);
      const types = new Set(p.flatTypes);
      if (!propFilter.some(t => types.has(t))) return false;
    }

    if (hasFilterSelected(FILTER_TENURE, filterSelected)) {
      const tenureFilter = Object.keys(filterSelected[FILTER_TENURE.field])
        .filter(o => filterSelected[FILTER_TENURE.field][o])
        .map(o => getTenureValue(o));
      const types = new Set(p.tenures.map(t => t.includes(' yrs ') ? parseInt(t.split(' yrs ')[0]).toString() : t));
      const nonFreeholdTypes = p.tenures.filter(t => t !== 'Freehold');
      if (!tenureFilter.some(t => types.has(t) || (t === 'Leasehold' && nonFreeholdTypes.length > 0))) return false;
    }

    if (hasFilterSelected(FILTER_LOCATION, filterSelected) || hasFilterSelected(FILTER_HDB_LOCATION, filterSelected)) {
      if (hasAnyValue(filterSelected[FIELD_AREAS])) {
        if (mode === MAP_MODE_HDB) {
          if (![...areaFilter].map(a => a.toUpperCase()).some(a => a === p.town)) return false;
        } else {
          if (!p.areas.some(a => areaFilter.has(a))) return false;
        }
      } else if (hasAnyValue(filterSelected[FIELD_DISTRICTS])) {
        if (!districtFilter.has(p.district)) return false;
      } else if (hasAnyValue(filterSelected[FIELD_REGIONS])) {
        if (!regionFilter.has(p.marketSegment)) return false;
      }
    }

    if (hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)) {
      const minDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[0]];
      if (minDate !== null) {
        if (p.lastTxDate < minDate) return false;
      }
      const maxDate = filterSelected[FILTER_MAP_TRANSACTION_DATE.field[1]];
      if (maxDate !== null) {
        if (p.lastTxDate > maxDate) return false;
      }
    }

    if (hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_HDB_BUYERS.field].value.min;
        if (p.hdbBuyers < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_HDB_BUYERS, filterSelected[FILTER_HDB_BUYERS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_HDB_BUYERS.field].value.max;
        if (p.hdbBuyers > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_TOTAL_UNITS.field].value.min;
        if (p.totalUnits < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_TOTAL_UNITS, filterSelected[FILTER_TOTAL_UNITS.field]);
      if (hasMax) {
        const maxVal = filterSelected[FILTER_TOTAL_UNITS.field].value.max;
        if (p.totalUnits > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_LAUNCH_PERC.field].value.min;
        if (p.soldAtLaunch < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_LAUNCH_PERC, filterSelected[FILTER_LAUNCH_PERC.field]);
      if (hasMax) {      
        const maxVal = filterSelected[FILTER_LAUNCH_PERC.field].value.max;
        if (p.soldAtLaunch > maxVal) return false;
      }
    }

    if (hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)) {
      const hasMin = hasMinRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMin) {
        const minVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.min;
        if (p.last6mAvgRentPsf < minVal) return false;
      }
      const hasMax = hasMaxRangeSelected(FILTER_PSF_RENTAL_6M, filterSelected[FILTER_PSF_RENTAL_6M.field]);
      if (hasMax) {
      const maxVal = filterSelected[FILTER_PSF_RENTAL_6M.field].value.max;
        if (p.last6mAvgRentPsf > maxVal) return false;
      }
    }
    
    return true;
  });

  const getDataByMode = (mode) => {
    if (mode === MAP_MODE_CONDO) return mapData;
    if (mode === MAP_MODE_LANDED) return landedMapData;
    if (mode === MAP_MODE_HDB) return hdbMapData;
  };

  const hasAdvDataByMode = (mode) => {
    if (mode === MAP_MODE_CONDO) return hasAdvMapData;
    if (mode === MAP_MODE_LANDED) return hasAdvLandedMapData;
    if (mode === MAP_MODE_HDB) return hasAdvHdbMapData;
  };

  const getModeMarkerData = (mode) => {
    const modeData = getDataByMode(mode);
    if (!modeData) {
      return [];
    }
    const props = mode === MAP_MODE_HDB
      ? (modeData ? modeData.blocks : [])
      : modeData.projects;

    // apply filter
    const filteredProps = filterProperties(props, filterSelected);
    return mode === MAP_MODE_HDB
      ? filteredProps
      : groupPropsByMarker(filteredProps);
  };

  /**
   * Property data (filtered)
   */
  const [filteredMarkers, setFilteredMarkers] = useState(() => getModeMarkerData(mode));
  const [filteredAreaMarkers, setFilteredAreaMarkers] = useState(() => groupMarkerByArea(filteredMarkers, mode));

  const filterOptions = mode === MAP_MODE_HDB ? HDB_MAP_FILTER_OPTIONS
    : (mode === MAP_MODE_LANDED ? LANDED_MAP_FILTER_OPTIONS : CONDO_MAP_FILTER_OPTIONS);

  const [numFilters, setNumFilters] = useState(filterOptions
    .reduce((s, opt) => s + (hasFilterSelected(opt, filterSelected) ? 1 : 0), 0));

  /**
   * Handle map clustering
   */
  const [clusters, setClusters] = useState([]);
  const [supercluster, setSupercluster] = useState(null);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    const newFilteredMarkers = getModeMarkerData(mode);
    setFilteredMarkers(newFilteredMarkers);
    setFilteredAreaMarkers(groupMarkerByArea(newFilteredMarkers, mode));

    // reset the number of filters
    setNumFilters(0);
  }, [mode]);

  useEffect(() => {
    const supercluster = new Supercluster({
      radius: mode === MAP_MODE_LANDED ? LANDED_SUPERCLUSTER_RADIUS : SUPERCLUSTER_RADIUS,
      maxZoom: MAX_CLUSTER_ZOOM
      // maxZoom: DEFAULT_PROPERTY_ZOOM
    });
    supercluster.load(filteredMarkers.map(prop => ({
      type: 'Feature',
      properties: {
        ...prop,
        id: `${prop.name}`,
        cluster: false
      },
      geometry: {
        type: 'Point',
        coordinates: [prop.lng, prop.lat]
      }
    })));
    setSupercluster(supercluster);
  }, [filteredMarkers]);

  useEffect(() => {
    if (supercluster && bounds && (zoom > DEFAULT_AREA_ZOOM || mode === MAP_MODE_HDB)) {
      // get all clusters within set bounds
      let clusters = supercluster.getClusters(bounds, zoom);
      
      // if (zoom >= DEFAULT_PROPERTY_ZOOM) {
      if (zoom >= MAX_CLUSTER_ZOOM) {
        // if hit max zoom for cluster, should flatten the cluster to locations and show unclustered
        clusters = clusters.flatMap(c => {
          // handle single location
          if (!c.properties.cluster) return [c];

          // handle cluster
          return supercluster.getLeaves(c.properties.cluster_id, Infinity);
        });

        setClusters(clusters);
      } else {
        // if have not hit max zoom, allow clustering
        try {
          // deliberately use a try-catch here so that if bounds change before supercluster when change map mode
          // it will not crash and wait for the supercluster change instead to update the markers
          clusters = mode === MAP_MODE_HDB
            ? clusters
                .map(c => {
                  // handle single location
                  if (!c.properties.cluster) return c;

                  // handle cluster
                  const projects = supercluster.getLeaves(c.properties.cluster_id, Infinity).map(m => m.properties);
                  let totalTx = 0;
                  let profitableSum = 0;
                  let profitableTotal = 0;
                  projects.forEach(p => {
                    totalTx += p.totalTx;
                    profitableSum += p.countProfitable;
                    profitableTotal += p.countHasProfit;
                  });
                  const profitable = profitableTotal > 0 ? (profitableSum / profitableTotal) : null;
                  return {
                    ...c,
                    projects,
                    totalProjects: projects.length,
                    totalTx,
                    profitable,
                    lat: c.geometry.coordinates[1],
                    lng: c.geometry.coordinates[0],
                    mapMode: MAP_MODE_HDB
                  };
                })
            : clusters
                .map(c => {
                  // handle single location
                  if (!c.properties.cluster) return c;

                  // handle cluster
                  const markers = supercluster.getLeaves(c.properties.cluster_id, Infinity).map(l => l.properties);
                  let totalTx = 0;
                  let profitableSum = 0;
                  let profitableTotal = 0;
                  let totalProjects = 0;
                  markers.forEach(p => {
                    totalTx += p.totalTx;
                    profitableSum += p.countProfitable;
                    profitableTotal += p.countHasProfit;
                    totalProjects += p.properties.length;
                  });
                  const profitable = profitableTotal > 0 ? (profitableSum / profitableTotal) : null;
                  return {
                    ...c,
                    markers,
                    projects: markers.flatMap(m => m.properties),
                    totalTx,
                    profitable,
                    totalProjects,
                    lat: c.geometry.coordinates[1],
                    lng: c.geometry.coordinates[0],
                    mapMode: mode
                  };
                });

          setClusters(clusters);
        } catch (err) {
          console.log('Error', err);
        }
      }
    }
  }, [supercluster, bounds]);

  // everytime user select a new target
  // should automatically clear the white tooltip
  useEffect(() => {
    setTooltipContent(null);
  }, [target]);

  const zoomToInitialView = () => {
    flyTo([103.831833, 1.304833], DEFAULT_FULL_ZOOM);
  };

  useEffect(() => {
    if (incomingFilter !== null) {
      const incomingFilterOptions = incomingFilter.mode === MAP_MODE_HDB
        ? HDB_MAP_FILTER_OPTIONS
        : (incomingFilter.mode === MAP_MODE_LANDED
            ? LANDED_MAP_FILTER_OPTIONS
            : CONDO_MAP_FILTER_OPTIONS
          )

      // perform a filter on property markers using this new filter conditions
      const newFilter = {
        ...initializeFilters(incomingFilterOptions, new URLSearchParams()),
        ...incomingFilter.filter
      };
      applyDataWithFilter(
        incomingFilter.mode,
        incomingFilter.data,
        newFilter
      );

      // apply the number of filters
      const filtersApplied = incomingFilterOptions
        .reduce((s, opt) => s + (hasFilterSelected(opt, newFilter) ? 1 : 0), 0);
      if (numFilters !== filtersApplied) {
        setNumFilters(filtersApplied);
      }

      // zoom to initial view
      zoomToInitialView();
    }
  }, [incomingFilter]);

  useEffect(() => {
    // apply the number of filters
    const filtersApplied = filterOptions.reduce((s, opt) => s + (hasFilterSelected(opt, filterSelected) ? 1 : 0), 0);
    setNumFilters(filtersApplied);
  }, [filterSelected]);

  const zoomToGpsLocation = (gpsLocation) => {
    if (gpsLocation && !gpsLocation.err) {
      flyTo([gpsLocation.lng, gpsLocation.lat], DEFAULT_PROPERTY_ZOOM);
    }
  };

  // handle user gps location for first time only
  useEffect(() => {
    if (gpsLocation && !gpsLocation.err && !hasInitGpsLocation) {
      setHasInitGpsLocation(true);
      if (!target && !focus) {
        zoomToGpsLocation(gpsLocation);
      }
    }
  }, [gpsLocation]);

  useEffect(() => {
    if (focus && focus.target && focus.target.lat && focus.target.lng) {
      if (focus.links && focus.links.length > 0) {
        // handle zoom to fill all coords and draw line from each coords to the target
        setTimeout(() => {
          const coords = [focus.target, ...focus.links];
          const bounds = new LngLatBounds();
          coords.forEach(c => bounds.extend([c.lng, c.lat]));
          const isSmallScreen = window.innerWidth < 600;
          const mediaQuery = checkMediaQuery();
          mapRef.current.fitBounds(bounds, {
            padding: mediaQuery ? (isSmallScreen ? 38 : 88) : 188
          });
          setTimeout(() => {
            const currentZoom = mapRef.current.getZoom();
            if (currentZoom <= DEFAULT_AREA_ZOOM)
              flyTo([focus.target.lng, focus.target.lat], DEFAULT_AREA_ZOOM + 0.1);
          }, 688);
        }, 188);
      } else {
        setTimeout(() => {
          if (focus.type === LOCATION_AREA) {
            flyTo([focus.target.lng, focus.target.lat], zoom);
          } else if (focus.zoomRadius) {
            setTimeout(() => {
              const deltaLat = focus.zoomRadius / 110574;
              const deltaLng = focus.zoomRadius / 111320;
              const bounds = [
                [focus.target.lng - deltaLng, focus.target.lat - deltaLat], // Southwest
                [focus.target.lng + deltaLng, focus.target.lat + deltaLat], // Northeast
              ];
              const mediaQuery = checkMediaQuery();
              mapRef.current.fitBounds(bounds, {
                padding: mediaQuery ? 18 : 68
              });
            }, 188);
          } else {
            let definedZoom = focus.zoom ?? (
              focus.target.mode === MAP_MODE_LANDED
                ? MAX_CLUSTER_ZOOM
                : null
            );
            if (definedZoom) {
              flyTo([focus.target.lng, focus.target.lat], definedZoom);
            } else {
              flyTo([focus.target.lng, focus.target.lat]);
            }
          }
        }, 188);
      }
    } else if (focus && focus.multiTargets && focus.multiTargets.length > 0) {
      // handle zoom to fill all coords only
      setTimeout(() => {
        const bounds = new LngLatBounds();
        focus.multiTargets.forEach(c => bounds.extend([c.lng, c.lat]));
        const isSmallScreen = window.innerWidth < 600;
        const mediaQuery = checkMediaQuery();
        mapRef.current.fitBounds(bounds, {
          padding: mediaQuery ? (isSmallScreen ? 38 : 88) : 188
        });
      }, 188);
    }
  }, [focus]);

  /* Handle map interaction */
  const onMapMoveEnd = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      const fraction = zoom < MIN_CLUSTER_DENSE_ZOOM ? 0 : (mode === MAP_MODE_LANDED ? 0.1 : 0.8);
      const latDiff = (bounds.getNorth() - bounds.getSouth()) * fraction;
      const lngDiff = (bounds.getEast() - bounds.getWest()) * fraction;
      setBounds([
        bounds.getWest() - lngDiff,
        bounds.getSouth() - latDiff,
        bounds.getEast() + lngDiff,
        bounds.getNorth() + latDiff
      ]);

      const currZoom = mapRef.current.getZoom();
      if (currZoom != zoom) setZoom(currZoom);
    }
    setAllowInteraction(true);
  };

  const onDebouncedZoom = useCallback(debounce(() => {
    const zoomLevel = mapRef.current.getZoom();
    setZoom(zoomLevel);
  }, DEBOUNCE_TIMING), []);

  const onMapZoom = () => {
    if (allowInteraction) {
      onDebouncedZoom();
    }
  };

  const flyTo = (coordinates, zoom = DEFAULT_PROPERTY_ZOOM) => {
    const map = mapRef.current?.getMap();
    if (!map) return;
    setAllowInteraction(false);

    map.flyTo({
      center: coordinates,
      essential: true,
      zoom: zoom,
      transitionDuration: 388
    });
  };

  const closeSideBar = () => {
    if (document.getElementById('mapSidebar').classList.contains('show')) {
      document.getElementById('mapside-close-button').click();
    }
  };

  const applyData = (data) => {
    const filteredProps = filterProperties(data, filterSelected);
    const filteredMarkers = mode === MAP_MODE_HDB
      ? filteredProps
      : groupPropsByMarker(filteredProps);
    setFilteredMarkers(filteredMarkers);
    setFilteredAreaMarkers(groupMarkerByArea(filteredMarkers, mode));
  };

  const applyDataWithFilter = (mode, data, incomingFilter) => {
    setFilterSelected(incomingFilter);
    const filteredProps = filterProperties(data, incomingFilter);
    const filteredMarkers = mode === MAP_MODE_HDB
      ? filteredProps
      : groupPropsByMarker(filteredProps);
    setFilteredMarkers(filteredMarkers);
    setFilteredAreaMarkers(groupMarkerByArea(filteredMarkers, mode));
  };

  const onApplyFilter = () => {
    const requireAdvData = mode === MAP_MODE_HDB
      ? (
          hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)
          || hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
          || hasFilterSelected(FILTER_SIZE, filterSelected)
          || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
        )
      : (
          hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)
          || hasFilterSelected(FILTER_TENURE, filterSelected)
          || hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
          || hasFilterSelected(FILTER_AVG_PRICE, filterSelected)
          || hasFilterSelected(FILTER_SIZE, filterSelected)
          || hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)
          || hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)
          || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
          || hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)
          || hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)
          || hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected)
        );

    if (requireAdvData && !hasAdvDataByMode(mode)) {
      loadAdvMapData(mode, getDataByMode(mode), (newData) => {
        applyData(newData);
      });
    } else {
      const modeData = getDataByMode(mode);
      applyData(mode === MAP_MODE_HDB ? modeData.blocks : modeData.projects);
    }
    closeSideBar();

    trackEvent('map_filter_apply', {
      mode,
      filter: filterSelected
    });
  };

  const onResetFilter = () => {
    const newFilter = initializeFilters(filterOptions, new URLSearchParams());
    const modeData = getDataByMode(mode);
    setFilterSelected(newFilter);
    setFilteredMarkers(mode === MAP_MODE_HDB ? modeData.blocks : modeData.markers);
    setFilteredAreaMarkers(groupMarkerByArea(mode === MAP_MODE_HDB ? modeData.blocks : modeData.markers, mode));
    convertMapUrl(null);

    trackEvent('map_filter_reset');
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && map.getLayer("pmtiles-layer")) {
      map.setPaintProperty("pmtiles-layer", "fill-opacity", getOverlaysOpacity(hiddenOverlayIds, overlayOpacity));
    }
  }, [overlayOpacity, hiddenOverlayIds]);

  const onMouseHover = (evt) => {
    if (mapRef.current?.getMap()?.getLayer("pmtiles-layer")) {
      const features = evt.target.queryRenderedFeatures(evt.point, {
        layers: [
          'pmtiles-layer',
          'school-layer',
          'station-layer'
        ],
      });

      if (features.length > 0) {
        const feature = features[0];
        const { x, y } = evt.point;
        const { lng, lat } = evt.lngLat;
        const layer = feature.layer.id;
        let label = null;
        let hint = null;
        if (layer === 'pmtiles-layer') {
          if (hiddenOverlayIds.indexOf(feature.properties.id) >= 0) return;
          label = OVERLAY_TYPES_MAP[feature.properties.id];
          if (feature.properties.id in OVERLAY_TYPES_HINT) {
            hint = OVERLAY_TYPES_HINT[feature.properties.id];
          }
        } else if (layer === 'school-layer') {
          const data = JSON.parse(feature.properties.data);
          label = data.label;
        } else if (layer === 'station-layer') {
          const data = JSON.parse(feature.properties.data);
          label = data.name;
        }
        if (label) {
          setTooltipContent({ layer, x, y, lat, lng, feature, label, hint });
        }
      } else if (tooltipContent) {
        setTooltipContent(null);
      }
    }
  };

  useEffect(() => {
    if (actionSignal) {
      if (actionSignal === 'resetFilter') {
        onResetFilter();
        setActionSignal(null);
      }
    }
  }, [actionSignal]);

  const onMouseClick = (evt) => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    const features = map.queryRenderedFeatures(evt.point, {
      layers: [
        'school-layer',
        'station-layer'
      ],
    });

    if (features.length) {
      const feature = features[0];
      const layer = feature.layer.id;
      if (layer === 'school-layer') {
        goToSchool(JSON.parse(feature.properties.data));
      } else if (layer === 'station-layer') {
        goToStation(JSON.parse(feature.properties.data));
      }
    }
  };

  const fetchAndAddImage = async (imgId, url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const imageBitmap = await createImageBitmap(blob);
    mapRef.current?.getMap()?.addImage(imgId, imageBitmap);
  };

  const isPrivateOffCenter = (mapData) => (
    mapRef.current?.getCenter()?.lat?.toFixed(5) !== mapData.projects[mapData.projectIndex[`${target?.id}_${target?.projectId}`]]?.lat?.toFixed(5)
    || mapRef.current?.getCenter()?.lng?.toFixed(5) !== mapData.projects[mapData.projectIndex[`${target?.id}_${target?.projectId}`]]?.lng?.toFixed(5)
  );

  const isHdbOffCenter = () => (
    mapRef.current?.getCenter()?.lat?.toFixed(5) !== hdbMapData.blocks[hdbMapData.index[target?.id]]?.lat?.toFixed(5)
    || mapRef.current?.getCenter()?.lng?.toFixed(5) !== hdbMapData.blocks[hdbMapData.index[target?.id]]?.lng?.toFixed(5)
  );

  const allowRecenter = target && target.type === LOCATION_PROPERTY
    && (target.mode === MAP_MODE_HDB ? isHdbOffCenter() : isPrivateOffCenter(
      target.mode === MAP_MODE_LANDED ? landedMapData : mapData
    ));

  const showModal = target?.type === LOCATION_PROPERTY
    || target?.type === LOCATION_MARKER
    || target?.type === LOCATION_AREA
    || target?.type === LOCATION_STATION
    || target?.type === LOCATION_SCHOOL
    || target?.type === LOCATION_UPCOMING
    || target?.type === LOCATION_COMPARE
    || target?.type === LOCATION_ESTATE;

  const mediaMatches = checkMediaQuery(); // check is mobile

  const mapMediaStyle = {
    height: mediaMatches && showModal ? 'calc(52svh - 132px)' : 'calc(100svh - 132px)',
    width: mediaMatches || !showModal ? '100svw' : '48svw',
  };

  const showAmenitiesMarkers = !!target;

  const handleMapMove = (evt) => {
    const viewState = evt.viewState;
    setPitch(viewState.pitch);
    setBearing(viewState.bearing);
    setZoom(viewState.zoom);
  };

  const loadOverlayTiles = (map) => {
    if (!map || map.getSource('pmtiles-source')) return;

    const pmtilesUrl = "https://realsmart.global.ssl.fastly.net/t/lul.pmtiles";
    const pmtiles = new PMTiles(pmtilesUrl);

    pmtiles.getHeader().then(() => {
      if (!map || map.getSource('pmtiles-source')) return;
      
      map.addSource("pmtiles-source", {
        type: "vector",
        tiles: [`pmtiles://${pmtilesUrl}/{z}/{x}/{y}.pbf`],
      });

      // Add a layer to render the PMTiles data
      map.addLayer({
        id: "pmtiles-layer",
        type: "fill",
        source: "pmtiles-source",
        "source-layer": "landuse",
        paint: {
          'fill-color': getOverlayFill(),
          "fill-opacity": getOverlaysOpacity(hiddenOverlayIds, overlayOpacity),
        },
      }, 'school-layer');
    });
  };

  return (
    <>
      {/* for disabling map interaction while moving */}
      {!allowInteraction && (
        <div
          style={{
            ...mapMediaStyle,
            position: 'absolute',
            top: '132px',
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 10,
            overflow: 'hidden'
          }}
        />
      )}

      {/* map */}
      <Map
        ref={mapRef}
        maxBounds={[103.45, 1.15, 104.2, 1.55]}
        mapStyle={getMapStyleFromTheme(mapStyle)}
        style={mapMediaStyle}
        pitch={pitch}
        bearing={bearing}
        initialViewState={{
          longitude: 103.831833,
          latitude: 1.304833,
          zoom: DEFAULT_FULL_ZOOM
        }}
        attributionControl={false}
        transformRequest={transformMapboxStyleRequest}
        maxZoom={20}
        onMoveStart={() => {
          setTooltipContent(null);
          setHasInitGpsLocation(true);
        }}
        onMove={handleMapMove}
        onMoveEnd={onMapMoveEnd}
        onZoom={onMapZoom}
        onMouseMove={onMouseHover}
        onMouseLeave={() => setTooltipContent(null)}
        onClick={onMouseClick}
        onStyleData={evt => {
          const map = evt.target;

          // load pmtiles for overlay every time there is a style change
          loadOverlayTiles(map);
        }}
        onLoad={evt => {
          const map = evt.target;

          // set initial map bounds
          const bounds = map.getBounds();
          setBounds([
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth()
          ]);

          // load images for symbol layers
          fetchAndAddImage('ic-mrt', '/img/general/ic_mrt.png');
          fetchAndAddImage('ic-lrt', '/img/general/ic_lrt.png');
          fetchAndAddImage('ic-school', '/img/general/school.png');
          fetchAndAddImage('ic-sg', '/img/general/sg.png');
        }}
        interactive
        pitchWithRotate
        dragRotate
        scrollZoom
        touchZoomRotate
      >
        {!checkMobile()
          && <NavigationControl
            position="bottom-left"
            showCompass
            showZoom
            visualizePitch
          />
        }

        <Source
          id="composite"
          type="vector"
        >
          <Layer {...{
            id: '3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: DEFAULT_AREA_ZOOM,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
              ],
              'fill-extrusion-opacity': blgOpacity
            }
          }} />
        </Source>

        {/* radius circle overlay */}
        {focus?.target && focus?.type !== LOCATION_AREA
          // && zoom > DEFAULT_AREA_ZOOM
          && <>
            <RadiusOverlay
              id="fine"
              target={focus.target}
              radiusKm={focus.fine}
            />
            <RadiusOverlay
              id="1km"
              target={focus.target}
              radiusKm={1}
              altRadius={focus.target.radius}
            />
            <RadiusOverlay
              id="2km"
              target={focus.target}
              radiusKm={2}
            />
          </>
        }

        {/* user location marker */}
        {gpsLocation && !gpsLocation.err
          && <UserMarker
            target={gpsLocation}
          />
        }

        {/* area markers */}
        {zoom <= DEFAULT_AREA_ZOOM && filteredAreaMarkers.map((a, i) =>
          <AreaMarker
            key={`arm-${i}`}
            idx={i}
            area={a}
            onClick={() => goToArea(a)}
            onMouseEnter={() => setIsHoverMarker(true)}
            onMouseLeave={() => setIsHoverMarker(false)}
            zoom={zoom}
            matchMedia={matchMedia}
            scheme={userConfig.profitableColor}
            unfadedProps={unfadedProps}
            mode={mode}
          />
        )}

        {zoom > DEFAULT_AREA_ZOOM
          && <>
            {/* condo cluster markers */}
            {clusters.filter(c => c.properties.cluster).map((c, i) =>
              <AreaMarker
                key={`apm-${i}`}
                idx={i}
                area={c}
                onClick={() => goToArea({ ...c, isCluster: true })}
                onMouseEnter={() => setIsHoverMarker(true)}
                onMouseLeave={() => setIsHoverMarker(false)}
                matchMedia={matchMedia}
                scheme={userConfig.profitableColor}
                unfadedProps={unfadedProps}
                mode={mode}
                isCluster
              />
            )}

            {/* project markers */}
            {clusters.filter(c => !c.properties.cluster).map((c, i) =>
              c.properties.mapMode === MAP_MODE_HDB
                ? <HdbMarker
                    key={`hpm-${i}`}
                    idx={i}
                    target={target}
                    property={c}
                    goToHdbBlock={goToHdbBlock}
                    onMouseEnter={() => setIsHoverMarker(true)}
                    onMouseLeave={() => setIsHoverMarker(false)}
                    matchMedia={matchMedia}
                    unfadedProps={unfadedProps}
                    scheme={userConfig.profitableColor}
                  />
                : <PropertyMarker
                    key={`ppm-${i}`}
                    idx={i}
                    target={target}
                    focus={focus}
                    property={c}
                    mediaMatches={mediaMatches}
                    goToMarker={goToMarker}
                    onMouseEnter={() => setIsHoverMarker(true)}
                    onMouseLeave={() => setIsHoverMarker(false)}
                    unfadedProps={unfadedProps}
                    scheme={userConfig.profitableColor}
                  />
            )}

            {/* upcoming project markers */}
            {(mode === MAP_MODE_CONDO || mode === MAP_MODE_LANDED)
                && (mapData?.upcomings ?? []).filter(u => (mode === MAP_MODE_CONDO && u.isCondo) || (mode === MAP_MODE_LANDED && u.isLanded))
                  .map((u, i) =>
              <UpcomingMarker
                key={`upm-${i}`}
                idx={i}
                property={u}
                goToProperty={goToUpcoming}
                onMouseEnter={() => setIsHoverMarker(true)}
                onMouseLeave={() => setIsHoverMarker(false)}
                scheme={userConfig.profitableColor}
              />
            )}
          </>
        }

        {/* block markers */}
        {focus?.target?.type === LOCATION_BLOCK
          && <BlockMarker
            idx={888}
            target={focus?.target}
          />
        }
        {focus?.links?.length > 0 && focus?.links?.some(l => l.type === LOCATION_BLOCK)
          && focus.links.map((l, i) =>
            <BlockMarker
              key={`blk-${i}`}
              idx={i}
              target={l}
            />
          )
        }

        {/* lines */}
        {focus?.links?.length > 0
          && <Source id="highlight-path-block-prop" type="geojson" data={generateLinesData(focus.target, focus.links)}>
            <Layer
              {...{
                id: 'route',
                type: 'line',
                paint: {
                  'line-color': '#0000ff',
                  'line-width': 2,
                  'line-dasharray': [2, 2],
                }
              }}
            />
          </Source>
        }

        {tooltipContent && !isHoverMarker
          && <div
            className="noselect"
            style={{
              position: "absolute",
              left: tooltipContent.x + 6,
              top: tooltipContent.y + 6,
              backgroundColor: "white",
              padding: "5px 10px",
              borderRadius: "6px",
              pointerEvents: "none",
              fontWeight: "600",
              maxWidth: '260px'
            }}
          >
            {tooltipContent.label}
            {tooltipContent.hint
              ? <div className="text-10 text-blue-1 lh-15">{tooltipContent.hint}</div>
              : <></>
            }
          </div>
        }

        {/* schools overlay */}
        <Source
          id="school-markers"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: showAmenitiesMarkers
              ? (mapData?.schools ?? []).map((school) => ({
                  type: 'Feature',
                  properties: {
                    id: school.postal,
                    data: school,
                    label: school.label,
                  },
                  geometry: { type: 'Point', coordinates: [school.lng, school.lat] },
                }))
              : []
          }}
        >
          <Layer
            id="school-layer"
            type="symbol"
            minzoom={DEFAULT_AREA_ZOOM}
            layout={{
              'icon-image': 'ic-school',
              'icon-size': 0.6,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
            }}
          />
        </Source>

        {/* stations overlay */}
        <Source
          id="station-markers"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: showAmenitiesMarkers
              ? (mapData?.stations ?? []).map((station) => ({
                  type: 'Feature',
                  properties: {
                    id: station.id,
                    data: station,
                    label: station.exit,
                    type: station.subtype
                  },
                  geometry: { type: 'Point', coordinates: [station.lng, station.lat] },
                }))
              : []
          }}
        >
          <Layer
            id="station-layer"
            type="symbol"
            minzoom={DEFAULT_AREA_ZOOM}
            layout={{
              'icon-image': [
                'match',
                ['get', 'type'],
                'MRT', 'ic-mrt',
                'LRT', 'ic-lrt',
                'ic-mrt'
              ],
              'icon-size': 0.9,
              'text-field': [
                'step', ['zoom'],
                '', 14, ['get', 'label']
              ],
              'text-size': 8,
              'text-font': ['Open Sans Bold'],
              'text-offset': [0, 1.8],
              'text-anchor': 'top',
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-ignore-placement': true,
            }}
            paint={{
              'text-color': '#000000',
              'text-halo-color': '#FFFFFF',
              'text-halo-width': 8,
            }}
          />
        </Source>

      </Map>

      {/* map buttons */}
      <div className="proj-map-bars noselect px-5" style={{ width: mapMediaStyle.width }}>
        <MapButtons
          theme={mapStyle}
          setTheme={setMapStyle}
          onSelectFilter={(content) => setSideContent(content)}
          // markerSchemeOptions={MARKER_SCHEME_OPTIONS}
          // markerScheme={markerScheme}
          // setMarkerScheme={setMarkerScheme}
          overlayOpacity={overlayOpacity}
          setOverlayOpacity={setOverlayOpacity}
          hiddenOverlayIds={hiddenOverlayIds}
          setHiddenOverlayIds={setHiddenOverlayIds}
          onSelectCompare={viewComparePro}
          numFilters={numFilters}
          userConfig={userConfig}
          setProfitableColorScheme={setProfitableColorScheme}
          mode={mode}
          setMode={setMode}
          allowModeSwitch={allowModeSwitch}
        />
        
        {/* {showZoomHint && zoom <= DEFAULT_AREA_ZOOM
          && <div className="container container-map-hint">
            <div className="alert alert-info text-14 text-center py-5 d-flex" role="alert">
              <span className="p-2 flex-grow-1">Zoom in to see properties instead of area clusters</span>
              <button
                className="p-2 button mr-10 text-blue-1 text-12"
                onClick={() => {
                  const map = mapRef.current?.getMap();
                  if (map) flyTo(map.getCenter(), DEFAULT_AREA_ZOOM + 0.1);
                }}
              >ZOOM IN</button>
              <button
                className="p-2 button ml-10 text-blue-1 text-12"
                onClick={() => setShowZoomHint(false)}
              >
                <i className="icon-close" />
              </button>
            </div>
          </div>
        } */}

      </div>

      {/* map side buttons */}
      <div className="proj-map-side-bars">
        <MapSideButtons
          userConfig={userConfig}
          gpsLocation={gpsLocation}
          getUserGpsLocation={() => {
            // first, zoom to previous gps location since it should not change too much and the wait can be long
            // this is to make button feel very responsive while waiting for final coordinates
            zoomToGpsLocation(gpsLocation);

            // fetch the latest coordinates
            // fetchGpsLocation((newGpsLocation) => zoomToGpsLocation(newGpsLocation));

            // for now do not zoom in to new location since user may have already interacted with map
            // this assumes that user last gps location is not far off from the latest, and the next gps update
            // will have the latest coord for next button click
            fetchGpsLocation();
          }}
          pitch={pitch}
          setPitch={setPitch}
          bearing={bearing}
          setBearing={setBearing}
          blgOpacity={blgOpacity}
          setBlgOpacity={setBlgOpacity}
          zoom={zoom}
          setZoom={setZoom}
        />
      </div>

      {!isMaximized && !hasSearchResults
        && <div
          className="proj-map-set noselect d-flex justify-content-center"
          style={{
            width: mapMediaStyle?.width,
            ...(matchMedia && window.innerWidth < 770 ? { bottom: (target && !isMinimized) ? '49svh' : '66px' } : {})
          }}
        >
          {/* {focus && focus.target?.id !== target?.id && focus.target?.type !== LOCATION_UPCOMING
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5 mr-5"
              onClick={() => setFocus(null)}
            >
              Clear Selection
              <i className="icon-close ml-5 text-10" />
            </button>
          } */}
          {allowRecenter
            && <button
              className="p-2 button -sm -dark-1 bg-white text-blue-1 border-light rounded-100 text-12 px-10 py-5"
              onClick={() => {
                const d = target.mode === MAP_MODE_HDB
                  ? hdbMapData.blocks[hdbMapData.index[target.id]]
                  : (
                    target.mode === MAP_MODE_CONDO
                      ? mapData.projects[mapData.projectIndex[getProjectKeyByTarget(target)]]
                      : landedMapData.projects[landedMapData.projectIndex[getProjectKeyByTarget(target)]]
                  );
                flyTo([d.lng, d.lat], mode === MAP_MODE_LANDED ? MAX_CLUSTER_ZOOM : DEFAULT_PROPERTY_ZOOM);
                setFocus({ target });

                trackEvent('map_recenter_click');
              }}
            >
              <i className="icon-location text-15 mr-5" />
              Re-center
            </button>
          }
        </div>
      }

      {/* overall map tooltip */}
      <Tooltip id="map-tooltip" style={{ zIndex: 900 }} />
      <Tooltip id="area-tooltip" />
      <Tooltip id="cluster-tooltip" />
      <Tooltip id="prop-tooltip" />
      <Tooltip id="block-tooltip" />
      <Tooltip id="upcoming-tooltip" />

      {/* filter and info side panel */}
      <div
        className={`offcanvas offcanvas-start filter-bar-nopad ${noSelectClass(user)}`}
        tabIndex="-1"
        id="mapSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            {sideContent?.type === 'filter' ? 'Filter Properties' : ''}
          </h5>
          <button
            id="mapside-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        {/* Filter */}
        {sideContent?.type === 'filter'
          && <>
            <div className="offcanvas-body">
              <aside className="sidebar y-gap-40 xl:d-block">
                <Sidebar
                  options={filterOptions}
                  filters={filterSelected}
                  setFilters={setFilterSelected}
                />
              </aside>
            </div>

            <div className="row ml-10 mr-10 mt-10 mb-10">
              <div className="col-3">
                <button
                  className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                  onClick={onResetFilter}
                >
                  Reset
                </button>
              </div>
              <div className="col-9">
                <button
                  className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                  onClick={onApplyFilter}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </>
        }

        {/* Compare */}

      </div>
    </>
  );
};

export default MainMap;
