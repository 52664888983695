import { useState } from "react";

const EditPostTag = ({
  idx,
  tag,
  onChange,
  onRemove
}) => {
  const [editTag, setEditTag] = useState(tag);

  const onConfirm = () => {
    if (editTag && editTag.length > 0) {
      onChange(editTag);
    }
  };

  return (
    <button
      key={tag}
      className="p-2 mr-5 button -dark-1 py-5 pl-15 pr-0 h-30 rounded-100 bg-blue-1 text-white text-12 mb-5"
    >
      {editTag
        && <span className="fw-600 mr-5">#</span>
      }
      <span
        id="text-measure"
        style={{
          visibility: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
        }}
        data-tooltip-id="edit-tooltip"
        data-tooltip-html="Edit tag"
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        {editTag}
      </span>
      <input
        id={`edit-tag-${idx}`}
        className="text-white"
        placeholder="tag name"
        tabIndex="0"
        style={{
          width: '1px',
          minWidth: '52px',
        }}
        value={editTag}
        onChange={e => setEditTag(e.target.value)}
        onInput={e => {
          const span = document.getElementById('text-measure');
          const inputValue = e?.target?.value ?? '';
          span.innerHTML = inputValue.trim() || e.target.placeholder;
          e.target.style.width = `${span.offsetWidth}px`;
        }}
        onFocus={e => {
          e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
        }}
        onBlur={e => {
          if (!editTag) {
            if (tag) {
              setEditTag(tag);
            } else {
              onRemove();
            }
          } else {
            onConfirm();
          }
        }}
      />
      {tag === editTag
        && <i
          className="icon-close pl-15 pr-10 py-10"
          data-tooltip-id="edit-tooltip"
          data-tooltip-html="Remove tag"
          data-tooltip-variant="dark"
          data-tooltip-place="bottom"
          onClick={onRemove}
        />
      }
      {tag !== editTag
        && <i
          className="icon-check pl-15 pr-10 py-10"
          data-tooltip-id="edit-tooltip"
          data-tooltip-html="Confirm tag"
          data-tooltip-variant="dark"
          data-tooltip-place="bottom"
          onClick={onConfirm}
        />
      }
    </button>
  );
};

export default EditPostTag;
