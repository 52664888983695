import { checkMediaQuery } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import { useEffect, useState } from "react";
import { getFile, getUpcomingPropertyData, trackPageView } from "@/utils/api";
import Loader from "../common/Loader";
import { NA } from "@/utils/convert";

const UpcomingView = ({
  user,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  setUnfadedProps
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const mediaMatches = checkMediaQuery();

  useEffect(() => {
    setUnfadedProps(new Set());
    setLoading(true);
    getUpcomingPropertyData(target.id, data => {
      setData(data);
      setLoading(false);
    }, err => {
      setLoading(false);
    });
  }, [target]);

  useEffect(() => {
    trackPageView('upcoming_detail', {
      property: target.id
    });
  }, []);

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect text-blue-1">
            <i className="p-2 icon-city-2 text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              New/Upcoming Project
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closePropertyDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          <div className="modal-body watermark d-flex">
            {!mediaMatches
              && <div
                className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                  <i className="icon-chevron-sm-down px-5" />
                </div>
              </div>
            }

            <div
              id="upcoming-details-modal"
              className={`p-2 modal-scoll-content ${
                isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
              }`}
            >

              <ScrollToTop />

              <section>

                <div className="mt-10 px-10">
                  <div className="d-flex items-center text-15">
                    <div className="fw-500">
                      {target.project}
                    </div>
                  </div>

                  <div className="row x-gap-50 y-gap-0 text-12 pt-5">
                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Street:</span>
                        {target.street}
                      </div>
                    </div>

                    {data?.developer
                      && <div className="col-12">
                        <div className="">
                          <span className="proj-lw">Developer:</span>
                          {data.developer}
                        </div>
                      </div>
                    }

                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Property Type:</span>
                        {target.types.join(', ')}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="">
                        <span className="proj-lw">Total Units:</span>
                        {data.units ? data.units.toLocaleString() : NA}
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingView;
