import { useEffect, useRef, useState } from "react";
import Loader from "@/components/common/Loader";
import Checkbox from "../common/sidebar/Checkbox";
import { useNavigate } from "react-router-dom";
import PropertyRow from "./PropertyRow";
import {
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  isFlatType
} from "@/utils/map";
import { trackEvent } from "@/utils/api";

const PLAY_STATES = [
  {
    id: 0,
    type: 'single',
    prompt: 'What do you want to look for?',
    key: 'type',
    options: [
      { option: 'Condo' },
      { option: 'Landed', next: 1 },
      { option: 'HDB', next: 2 }
    ]
  },
  {
    id: 1,
    prompt: 'Are you looking for freehold or leasehold?',
    key: 'tenure',
    options: [
      { option: 'Freehold' },
      { option: 'Leasehold' }
    ]
  },
  {
    id: 2,
    prompt: 'What type of flat are you looking for?',
    key: 'flat',
    options: [
      { option: '1 Room' },
      { option: '2 Room' },
      { option: '3 Room' },
      { option: '4 Room' },
      { option: '5 Room' },
      { option: 'Executive' },
      // { option: 'Multi-Generation' },
    ]
  }
];

const PROPERTY_TYPE_MAP = {
  'Condo': [
    'Apt/Condo',
    'Executive Condominium'
  ],
  'Landed': [
    'Detached House',
    'Semi-Detached House',
    'Terrace House'
  ]
};

const BotPanel = ({
  onGoToCondo,
  onGoToLanded,
  onGoToHdb,
  onGoToCondoMap,
  onGoToLandedMap,
  onGoToHdbMap,
  onRestart,
  position,
  showBotPanelTs,
  hidden = false,
  hint = "Realsmart Recommended",
  // hint = "I'm searching for ...",
}) => {
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [inputSelected, setInputSelected] = useState({});
  const [hasInitLoad, setHasInitLoad] = useState(false);
  const [playState, setPlayState] = useState(0);
  const [displayList, setDisplayList] = useState([]);
  const [results, setResults] = useState(null);

  const createFirstBotMessage = () => {
    setTimeout(() => {
      setDisplayList([{
        bot: true,
        content: PLAY_STATES[0].prompt
      }]);
    }, 300);
  };

  const onOpen = () => {
    if (!hasInitLoad) {
      setHasInitLoad(true);
      
      // create first message
      createFirstBotMessage();

      trackEvent('prompt_open');
    }
  };

  useEffect(() => {
    if (showBotPanelTs) {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
    }
  }, [showBotPanelTs]);

  const reset = (autoTrigger) => {
    setHasInitLoad(false);
    setSelected({});
    setInputSelected({});
    setPlayState(0);
    setDisplayList([]);
    setResults(null);
    if (autoTrigger) {
      createFirstBotMessage();
    }
    onRestart?.();
    trackEvent('prompt_restart');
  };

  const checkIsCondo = (types) => types.some(t => isFlatType(t));

  const process = (selected) => {
    // TODO
    setPlayState(null);
    if (!selected.type || selected.type.length === 0 || selected.type[0] !== 'HDB') {
      // handle all privates (default)
      // getFile('pr', 'c', txt => {
      //   const props = txt.split('\n').map(t => decompressBasicPropertyData(t));
      //   const selectedCondoType = !selected.type || selected.type.length === 0 || selected.type[0] === 'Condo';
      //   const tenureFilter = !selected.tenure || selected.tenure.length !== 1 ? null : selected.tenure[0];
      //   setResults(
      //     props.filter(p => !selected.type || checkIsCondo(p.types) === selectedCondoType)
      //       .filter(p => !tenureFilter
      //         || (tenureFilter === 'Freehold' && p.tenures.some(t => t === tenureFilter))
      //         || (tenureFilter !== 'Freehold' && p.tenures.every(t => t !== 'Freehold')))
      //       .sort((a, b) => b.profitable - a.profitable)
      //       .map(p => ({ ...p, mode: MAP_MODE_CONDO }))
      //   );
      // }, err => {
      //   setLoading(false);
      // });
    } else {
      // handle all hdbs
      // getFile('pr', 'h', txt => {
      //   const props = txt.split('\n').map(t => decompressHdbL1Data(t));
      //   const typeFilter = selected.flat ? new Set(selected.flat.map(t => t.toUpperCase())) : null;
      //   setResults(
      //     props.filter(p => !typeFilter || p.flatTypes.some(t => typeFilter.has(t)))
      //       .sort((a, b) => b.profitable - a.profitable)
      //       .map(p => ({ ...p, mode: MAP_MODE_HDB }))
      //   );
      // }, err => {
      //   setLoading(false);
      // });
    }
  };

  const goToProperty = (data) => {
    if (data.mode === MAP_MODE_HDB) {
      trackEvent('prompt_select', {
        type: 'hdb',
        property: data.name
      });

      if (onGoToHdb) {
        onGoToHdb(data.postal);
      } else {
        navigate(`/map?mode=h&id=${data.postal}`);
      }
    } else {
      trackEvent('prompt_select', {
        type: 'hdb',
        property: data.marker
      });

      const isCondo = checkIsCondo(data.types);
      if (isCondo) {
        if (onGoToCondo) {
          onGoToCondo(data.marker, data.projectId);
        } else {
          navigate(`/map?mode=c&id=${data.marker}&p=${data.projectId}`);
        }
      } else {
        if (onGoToLanded) {
          onGoToLanded(data.marker, data.projectId);
        } else {
          navigate(`/map?mode=c&id=${data.marker}&p=${data.projectId}`);
        }
      }
    }
  };

  const goToMap = () => {
    if (!selected.type) {
      trackEvent('prompt_select', {
        type: 'view_all',
        target: 'condo',
        filter_type: 'default',
        filter_tenure: 'default'
      });

      // if no type selected, default is to go to main map (condo)
      if (onGoToCondoMap) {
        onGoToCondoMap();
      } else {
        navigate('/map');
      }
    } else {
      if (selected.type[0] === 'HDB') {
        const flatTypes = selected.flat?.map(t => t.toUpperCase());

        trackEvent('prompt_select', {
          type: 'view_all',
          target: 'hdb',
          filter_type: flatTypes.join(',')
        });

        // handle hdb
        if (onGoToHdbMap) {
          onGoToHdbMap(flatTypes);
        } else {
          navigate(`/map?mode=h${
            flatTypes
              ? `&flat_type=${encodeURIComponent(flatTypes.join(','))}`
              : ''
          }`);
        }
      } else if (selected.type[0] === 'Condo') {
        const types = PROPERTY_TYPE_MAP[selected.type[0]];

        trackEvent('prompt_select', {
          type: 'view_all',
          target: 'condo',
          filter_type: types.join(','),
          filter_tenure: selected.tenure?.join(',')
        });

        // handle condo
        if (onGoToCondoMap) {
          onGoToCondoMap(selected.tenure);
        } else {
          navigate(`/map?mode=c&prop_type=${encodeURIComponent(types.join(','))}${
            selected.tenure
              ? `&tenure=${encodeURIComponent(selected.tenure.join(','))}`
              : ''
          }`);
        }
      } else if (selected.type[0] === 'Landed') {
        const types = PROPERTY_TYPE_MAP[selected.type[0]];

        trackEvent('prompt_select', {
          type: 'view_all',
          target: 'landed',
          filter_type: types.join(','),
          filter_tenure: selected.tenure?.join(',')
        });

        // handle landed
        if (onGoToLandedMap) {
          onGoToLandedMap(selected.tenure);
        } else {
          navigate(`/map?mode=c&prop_type=${encodeURIComponent(types.join(','))}${
            selected.tenure
              ? `&tenure=${encodeURIComponent(selected.tenure.join(','))}`
              : ''
          }`);
        }
      }
    }
  };

  const onSelectInput = () => {
    if (getSelectedInputOptions().length === 0) {
      // handle skip and do the query
      process(selected);
    } else {
      // handle next step for the bot for additional prompts
      const nextStep = PLAY_STATES[playState].options.find(o => o.option === getSelectedInputOptions()[0])?.next;
      
      // post user message
      const userMsg = { content: getSelectedInputOptions().join(', ') };
      setDisplayList([ ...displayList, userMsg ]);

      // persist the user selection
      const newSelected = {
        ...selected,
        [PLAY_STATES[playState].key]: getSelectedInputOptions()
      };
      setSelected(newSelected);

      // reset user input
      setInputSelected({});

      if (nextStep) {
        setPlayState(null);

        setTimeout(() => {
          setDisplayList([
            ...displayList,
            userMsg,
            {
              bot: true,
              content: PLAY_STATES[nextStep].prompt
            }
          ]);

          setPlayState(nextStep);
        }, 200);
      } else {
        // do the query
        process(newSelected);
      }
    }
  };

  const getSelectedInputOptions = () => Object.keys(inputSelected).filter(o => inputSelected[o]);

  const filterTop = (results) => {
    const top = [];
    const seen = new Set();
    results.forEach(r => {
      const key = r.projectId ? r.name : r.postal;
      if (seen.has(key)) return;
      seen.add(key);
      top.push(r);
    });
    return top;
  };

  useEffect(() => {
    if (playState !== null
      && PLAY_STATES[playState].type === 'single'
      && Object.keys(inputSelected).length === 1
    ) {
      // automatically skip the select button if already selected 1
      // there is no need to hide the button since initial state for unselected will be to skip
      onSelectInput();
    }
  }, [inputSelected]);

  return (
    <>
      <div
        className="fab-container noselect"
        style={position
          ? position
          : {
              bottom: '10px',
              right: '10px'
            }
        }
        hidden={hidden}
      >
        <div
          className="fab-bubble"
          data-bs-toggle="offcanvas"
          data-bs-target="#botPanel"
          onClick={onOpen}
        >
          {hint}
        </div>
        <button
          ref={buttonRef}
          className="fab-button"
          data-bs-toggle="offcanvas"
          data-bs-target="#botPanel"
          onClick={onOpen}
        >
          <img src="/img/general/messenger.png" height={25} width={25} />
        </button>
      </div>

      {/* Panel */}
      <div
        className="offcanvas offcanvas-end filter-bar-nopad noselect"
        tabIndex="-1"
        id="botPanel"
        onTouchMove={e => {
          e.stopPropagation();
        }}
        onWheel={e => {
          e.stopPropagation();
        }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-16" id="offcanvasLabel">
            REAL SMART ASSISTANT
          </h5>
          <button
            id="bot-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body watermark noselect fab-min-pad">
          <aside className="sidebar xl:d-block fab-content">
            {loading
              && <div className="loader-container">
                <Loader />
              </div>
            }
            
            {!loading
              && <div className="d-flex flex-column fab-content px-0">
                {!results
                  && <div className="p-2 flex-grow-1 px-10">
                    {displayList.map(row =>
                      row.bot
                      ? (
                          <div className="fab-chat">
                            {row.content}
                          </div>
                        )
                      : (
                          <div className="d-flex flex-row-reverse">
                            <div className="fab-chat fab-user-chat">
                              {row.content}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                }

                {results
                  && <div className="p-2 flex-fill px-0">
                    {
                      filterTop(results).slice(0, 10).sort((a, b) => b.score - a.score).map((r, i) => (
                        <PropertyRow
                          id={i}
                          mode={
                            !selected.type || selected.type.length === 0 || selected.type[0] !== 'HDB'
                              ? MAP_MODE_CONDO
                              : MAP_MODE_HDB
                          }
                          hideRealscore={selected?.type?.[0] === 'HDB'}
                          data={r}
                          onView={goToProperty}
                          usePlaceImg
                          dismissable
                        />
                      ))
                    }

                    <div
                      className="text-center text-blue-1 text-12 fw-500 pt-10 cursor-pointer"
                      onClick={goToMap}
                    >
                      View map for more
                    </div>
                  </div>
                }

                {playState !== null && PLAY_STATES[playState].options
                  && <div className="p-2 border-top-light">
                    <div className="fw-600 text-14 text-blue-1">
                      {PLAY_STATES[playState].type === 'single'
                        ? 'Select only 1:'
                        : 'Select 1 or more:'
                      }
                    </div>

                    {PLAY_STATES[playState].options.map(o => (
                      <Checkbox
                        label={o.option}
                        value={inputSelected[o.option]}
                        setValue={v => {
                          if (PLAY_STATES[playState].type === 'single') {
                            setInputSelected({ [o.option]: v });
                          } else {
                            setInputSelected({ ...inputSelected, [o.option]: v });
                          }
                        }}
                        textSize="14"
                      />
                    ))}

                    <div className="d-flex mt-20">
                      <button
                        className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12"
                        onClick={reset}
                      >
                        <span className="px-5">
                          Restart
                        </span>
                      </button>
                      <div className="p-2 flex-grow-1"></div>
                      <button
                        className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12"
                        onClick={onSelectInput}
                      >
                        <span className="px-5">
                          {getSelectedInputOptions().length > 0
                            ? 'Select'
                            : 'Skip'
                          }
                        </span>
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
          </aside>
        </div>

        {results
          && <div className="p-2 border-top-light">
            <div className="d-flex mt-10">
              <button
                className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12"
                onClick={reset}
              >
                <span className="px-5">
                  Restart
                </span>
              </button>
              <button
                className="p-2 flex-grow-1 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12"
                onClick={goToMap}
                data-bs-dismiss="offcanvas"
              >
                <span className="px-5">
                  View all properties on map
                </span>
              </button>
            </div>
          </div>
        }

      </div>
    </>
  );
};

export default BotPanel;
