import { checkMediaQuery } from "@/utils/user";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import EditPostTag from "./EditPostTag";

const EditPost = ({
  content,
  onClose
}) => {
  const parseExistingImgs = (imgs) => imgs?.map(img => ({ img, uploaded: true }));

  const [editText, setEditText] = useState(content?.text);
  const [editGallery, setEditGallery] = useState(parseExistingImgs((!content?.gallery && content?.img) ? [content.img] : content?.gallery) ?? []);
  const [editTags, setEditTags] = useState([]);
  const [currTagId, setCurrTagId] = useState(null);

  const [uploadProgress, setUploadProgress] = useState({});

  const mediaQuery = checkMediaQuery();

  const addPhoto = () => {
    // TODO
  };

  const removePhoto = (idx) => {
    const newGallery = [...editGallery];
    newGallery.splice(idx, 1);
    setEditGallery(newGallery);
  };

  const onAddNewTag = () => {
    const idx = editTags.length;
    setEditTags([...editTags.filter(tag => tag !== ''), '']);
    setTimeout(() => {
      const tagInput = document.getElementById(`edit-tag-${idx}`);
      tagInput.focus();
    }, 100);
  };

  const onChangeTag = (idx, tag) => {
    const newTags = [...editTags];
    newTags[idx] = tag;
    setEditTags(newTags);
  };

  const onRemoveTag = (idx) => {
    const newTags = [...editTags];
    newTags.splice(idx, 1);
    setEditTags(newTags);
  };

  const onFileSelect = (evt) => {
    const files = Array.from(evt.target.files);
    const initialProgress = {};
    files.forEach(f => {
      initialProgress[f.name] = 0;
    });
    setEditGallery([...editGallery, ...files.map(f => ({ file: f, uploaded: false }))]);
    setUploadProgress(initialProgress);
    simulateUploadProgress(files);
  };

  const simulateUploadProgress = (files) => {
    files.forEach((file) => {
      const interval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const currentProgress = prevProgress[file.name] || 0;
          if (currentProgress >= 100) {
            clearInterval(interval);
            return prevProgress;
          }
          return {
            ...prevProgress,
            [file.name]: currentProgress + 10, // Increment by 10%
          };
        });
      }, 500); // Update every 500ms
    });
  };

  return (
    <div className={`newsfeed-content newsfeed-post ${mediaQuery ? 'newsfeed-post-full' : ''} px-15 py-10`}>
      {/* header */}
      <div className="d-flex justify-content-between">
        <div className="p-2 text-14 fw-600">
          {content ? 'Edit' : 'Create'} Post
        </div>

        <div className="p-2 flex-grow-1"></div>

        <button
          className="p-2 button h-30 px-10 text-16 py-20"
          onClick={onClose}
        >
          <i className="icon-close py-5" />
        </button>
      </div>

      {/* content body */}
      <div className="news-post-ch py-10 watermark">

        <div className="row px-20">
          <textarea
            className="col-12 newsfeed-input px-10 py-10 text-14"
            placeholder="Write your post content here"
            value={editText}
            onChange={evt => setEditText(evt.target.value)}
          />
        </div>

        <div className="p-2 mt-5">
          <div className="p-2 text-14 fw-600">
            Gallery
          </div>
          <div className="d-flex">
            <input
              type="file"
              accept="image/*"
              multiple
              id="file-upload"
              onChange={onFileSelect}
              hidden
            />
            <label
              htmlFor="file-upload"
              className="p-2 d-flex justify-content-center align-items-center px-10 py-10 cursor-pointer post-btn"
              data-tooltip-id="edit-tooltip"
              data-tooltip-html="Add photo to post"
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              style={{
                width: '120px',
                height: '120px',
                background: '#ecf0f1',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            >
              <i className="icon-plus" />
            </label>
            
            <div
              className="p-2 flex-grow-1 photo-gallery d-flex"
              style={{
                display: 'flex',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              {editGallery.map((d, i) => (
                d.uploaded
                  ? <div
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                        flex: '0 0 auto',            
                      }}
                    >
                      <img
                        loading="lazy"
                        src={d.img}
                        style={{
                          cursor: 'default',
                          display: 'block',
                        }}
                      />
                      <button
                        className="rounded-100 post-btn"
                        data-tooltip-id="edit-tooltip"
                        data-tooltip-html="Remove photo from post"
                        data-tooltip-variant="dark"
                        data-tooltip-place="bottom"
                        style={{
                          position: 'absolute',
                          top: '6px',
                          right: '12px',
                          backgroundColor: 'rgba(52, 73, 94, 0.3)',
                          color: '#fff',
                          border: 'none',
                          padding: '2px 8px 0px 8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => removePhoto(i)}
                      >
                        <i className="icon-trash" />
                      </button>
                    </div>
                  : <div
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                        flex: '0 0 auto',             
                      }}
                    >
                      <img
                        loading="lazy"
                        src={URL.createObjectURL(d.file)}
                        style={{
                          cursor: 'default',
                          display: 'block',
                        }}
                      />
                      {(!uploadProgress[d.file.name] || uploadProgress[d.file.name] < 100)
                        && <div className="post-progress-bar">
                          <div
                            className="post-progress"
                            style={{
                              width: `${uploadProgress[d.file.name] || 0}%`,
                            }}
                          ></div>
                        </div>
                      }
                      {!(!uploadProgress[d.file.name] || uploadProgress[d.file.name] < 100)
                        && <button
                          className="rounded-100 post-btn"
                          data-tooltip-id="edit-tooltip"
                          data-tooltip-html="Remove photo from post"
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                          style={{
                            position: 'absolute', // Positions the button relative to the parent div
                            top: '6px', // Adjust as needed
                            right: '12px', // Adjust as needed
                            backgroundColor: 'rgba(52, 73, 94, 0.3)',
                            color: '#fff',
                            border: 'none',
                            padding: '2px 8px 0px 8px',
                            cursor: 'pointer',
                          }}
                          onClick={() => removePhoto(i)}
                        >
                          <i className="icon-trash" />
                        </button>
                      }
                    </div>
              ))}
            </div>
          </div>

        </div>

        <div className="p-2 mt-5 mb-20">
          <div className="p-2 text-14 fw-600">
            Tags
          </div>
          <div className="d-flex flex-wrap">
            <button
              className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12 mr-5"
              data-tooltip-id="edit-tooltip"
              data-tooltip-html="Add tag"
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              onClick={onAddNewTag}
            >
              <i className="icon-plus" />
            </button>
            {editTags.map((tag, i) =>
              <EditPostTag
                idx={i}
                tag={tag}
                onChange={(tag) => onChangeTag(i, tag)}
                onRemove={() => onRemoveTag(i)}
              />
            )}
          </div>
        </div>

        <div
          className="d-flex flex-row-reverse"
          style={{
            position: 'absolute',
            bottom: '16px',
            right: '16px'
          }}
        >
          <button
            className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
          >
            {content ? 'Update' : 'Create'} Post
          </button>
        </div>

      </div>

      <Tooltip id="edit-tooltip" />
    </div>
  );
};

export default EditPost;
