import { useEffect } from "react";
import { getTimeLabel } from "@/utils/time";

const Post = ({
  id,
  content,
  onClick,
  onSeenPost
}) => {
  const hasBgId = content.bgId !== null && content.bgId !== undefined;

  const onSeen = () => {
    onSeenPost?.(id, content);
  };

  useEffect(() => {
    if (onSeenPost) {
      const element = document.getElementById(`post-${id}`);
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            onSeen();
            observer.unobserve(entry.target); // Stop observing after it's visible
          }
        },
        { root: null, threshold: 0.1 }
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element) observer.disconnect();
      };
    }
  }, [id]);

  return (
    <div
      id={`post-${id}`}
      key={id}
      style={{
        width: '100%',
        position: 'relative',
        border: '1px solid #ecf0f1',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
        display: "block",
      }}
      onClick={() => onClick?.(content)}
    >
      {content.img
        && <div
          className=""
          style={{
            position: 'relative'
          }}
        >
          <img
            src={content.img}
            style={{
              display: "block",
              width: "100%",
              height: "auto",
              maxHeight: "360px",
              objectPosition: "center",
              backgroundSize: "cover",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          />
          <div
            className="text-white text-10 d-flex"
            style={{
              position: "absolute",
              top: "6px",
              right: "6px",
            }}
          >
            <div
              className="rounded-100 d-flex align-items-center ml-5"
              style={{
                backgroundColor: "#e84393",
                padding: '0px 6px'
              }}
            >
              <i className="icon-heart mr-5" />
              {content.likes ?? 0}
            </div>
            {content.comments
              && <div
                className="rounded-100 d-flex align-items-center ml-5"
                style={{
                  backgroundColor: "#6c5ce7",
                  padding: '0px 6px'
                }}
              >
                <i className="icon-newsletter mr-5" />
                {content.comments}
              </div>
            }
          </div>
        </div>
      }
      {content.text
        && <div
            className={`text-12 lh-15 post-prev-line${content.img ? '' : '-max'} ${content.img ? '' : `post-txt-bg-${hasBgId ? content.bgId : 'none'} ${hasBgId ? 'text-white' : ''}`}`}
            style={{
              padding: '3px 6px'
            }}
          >
            {content.text}
          </div>
      }
      <div
        className="d-flex"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "#fff",
          padding: "8px",
          boxSizing: "border-box",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          alignItems: "center",
        }}
      >
        <img
          className="p-2 px-0 py-0"
          src={content.avatar ?? '/img/general/profile.png'}
          style={{
            width: "38px",
            height: "38px",
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: "12px",
          }}
        />
        <span
          className="p-2 flex-grow-1 text-14 py-0"
          style={{ fontWeight: "bold" }}
        >
          {content.author}
        </span>
        <span
          className="p-2 text-10 py-0"
        >
          {getTimeLabel(content.createdAt)}
        </span>
      </div>
    </div>
  );
};

export default Post;
