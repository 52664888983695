import { LOCATION_BLOCK, LOCATION_SCHOOL } from "@/utils/areas";
import { calculateDist, deepCopy, formatDistanceLabel } from "@/utils/convert";

const NearbySchools = ({
  blocks,
  schools,
  focus,
  setFocus,
  isMaximized,
  handleExpand
}) => {
  const isXl = window.matchMedia("(min-width: 1200px)").matches;

  const lists = [
    {
      title: 'Primary Schools within 1km',
      list: schools
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(s => ({
          ...s,
          blocks: blocks
            .map(b => {
              if (s.blocks) {
                const foundBlock = s.blocks.find(sb => b.postal === sb);
                if (foundBlock) {
                  // since distance is not in the source data, we will estimate using coordinates first
                  // and then floor it if it exceeds 1km, instead of using the calculated distance which may > 1km and be hidden
                  // even if the actual fact that this block is considered within 1km based on geometry
                  const dist = calculateDist(s.lat, s.lng, b.lat, b.lng);
                  return {
                    ...b,
                    distance: Math.min(1000, dist),
                    type: LOCATION_BLOCK,
                    radius: dist
                  };
                }
              }
              // fallback to main calculation for backward compatibility for old cache
              return {
                ...b,
                distance: calculateDist(s.lat, s.lng, b.lat, b.lng),
                type: LOCATION_BLOCK
              };
            })
            .filter(b => b.distance <= 1000)
            .sort((a, b) => a.distance - b.distance),
        }))
        .filter(s => s.blocks.length > 0)
    },
  ];

  const hasSelectedSchool = (school) => {
    if (hasNoSelection) return;
    return focus.target?.name === school.name;
  };

  const hasSelectedBlock = (block) => {
    if (hasNoSelection) return;
    return !!focus.links?.find(l => l.postal === block.postal);
  };

  const isFocusBlock = (block) => focus?.target?.id === block.block;

  const getBlockSchools = (block) => {
    const schools = [];
    lists.forEach(l => l.list.forEach(s => {
      if (s.blocks.filter(b => b.postal === block.postal).length > 0) {
        schools.push(s);
      }
    }));
    return schools;
  };

  const hasAnyBlockSelected = (blocks) => {
    if (hasNoSelection) return;
    return blocks.some(b => focus?.target?.block === b.block);
  };

  const hasNoSelection = !focus || focus.use !== 'nearby-sch' || focus.links?.length === 0;

  const getSchoolRank = (postal) => {
    // TODO
    return null;
  };

  const isTopSchool = (postal) => {
    const rank = getSchoolRank(postal);
    if (!rank) return false;
    return rank <= 10;
  };

  return (
    <div className="pt-10 mb-50">
      {schools.length === 0
        && <div className="py-20 text-14 fw-500 text-center">No schools nearby</div>
      }

      {schools.length > 0 && lists.filter(l => l.list?.length > 0).map(l => (
        <div className="row mt-20 mb-10">
          <div className="d-flex items-center">
            <i className="icon-compass text-20 mr-10"></i>
            <div className="text-20 fw-500">{l.title}</div>
          </div>

          {l.list.map((subsection) => (
            <div className={isXl && isMaximized ? 'col-4' : 'col-lg-6 col-md-12'}>
              <div className="">
                <div className="mb-20 md:mb-10 mt-20">
                  <div
                    className="d-flex items-center mb-10 hover-blue-row cursor-pointer"
                    onClick={() => {
                      if (hasSelectedSchool(subsection)) {
                        setFocus(null);
                      } else {
                        const focusTarget = {
                          ...subsection,
                          id: subsection.postal
                        };
                        const adjustRadius = subsection.blocks.filter(b => b.radius).map(b => b.radius);
                        if (adjustRadius.length > 0) {
                          focusTarget.radius = Math.max(...adjustRadius);
                        }
                        setFocus({
                          use: 'nearby-sch',
                          target: focusTarget,
                          mode: LOCATION_SCHOOL,
                          links: subsection.blocks
                        });
                        if (isMaximized) {
                          handleExpand();
                        }
                      }
                    }}
                  >
                    <div
                      className="ml-10 text-14 fw-500"
                      style={{
                        opacity: hasNoSelection
                          || hasSelectedSchool(subsection)
                          || (focus?.mode === LOCATION_BLOCK && hasAnyBlockSelected(subsection.blocks))
                        ? 1 : 0.2
                      }}
                    >
                      {subsection.name} {
                        isTopSchool(subsection.postal) ?
                          <>
                            <br />
                            <span className="text-10 lh-10 items-center text-white proj-beta noselect rounded-100 fw-500 noselect">
                              Top 10 (Ranked by ChatGPT)
                            </span>
                          </>
                        : ''
                      }
                    </div>
                  </div>

                  <div
                    className={`row y-gap-10 x-gap-0 px-10 ${
                      isXl ? 'surrounding-fh' : ''
                    }`}
                  >
                    {subsection.blocks.map((b, i) => (
                      <div
                        className="col-12 border-top-light"
                        key={i}
                        style={{
                          opacity: hasNoSelection
                            || (hasSelectedSchool(subsection) && hasSelectedBlock(b))
                            || isFocusBlock(b)
                          ? 1 : 0.2
                        }}
                      >
                        <div
                          className="row items-center justify-between"
                        >
                          <div className="col-9">
                            <div
                              className="text-13 hover-blue-row cursor-pointer"
                              onClick={() => {
                                if (isFocusBlock(b)) {
                                  setFocus(null);
                                } else {
                                  setFocus({
                                    use: 'nearby-sch',
                                    target: { ...b, id: b.block },
                                    mode: LOCATION_BLOCK,
                                    links: getBlockSchools(b)
                                  });
                                  if (isMaximized) {
                                    handleExpand();
                                  }
                                }
                              }}
                            >
                              {b.block} {b.address} <span className="text-blue-1 pl-10">{b.postal}</span>
                            </div>
                          </div>

                          <div className="col-3">
                            <div className="text-13 text-right">
                              {formatDistanceLabel(b.distance > 1000 ? 1000 : b.distance)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NearbySchools;
